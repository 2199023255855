// src/components/Connector/ConnectorCategories.js
import React, { useState } from 'react';

const ConnectorCategories = ({ categories, onSelectCategory }) => {
  const [selected, setSelected] = useState('All');

  const handleSelect = (category) => {
    setSelected(category);
    onSelectCategory(category);
  };

  return (
    <div className="w-full lg:w-1/2">
      <h4 className="text-md font-medium text-gray-700 dark:text-gray-300 mb-2">Filter by Category</h4>
      <div className="flex flex-wrap gap-2">
        <button
          onClick={() => handleSelect('All')}
          className={`px-3 py-1 rounded-full text-sm font-medium transition ${
            selected === 'All'
              ? 'bg-primary text-white'
              : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
          }`}
        >
          All
        </button>
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => handleSelect(category)}
            className={`px-3 py-1 rounded-full text-sm font-medium transition ${
              selected === category
                ? 'bg-primary text-white'
                : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
            }`}
          >
            {category}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ConnectorCategories;
