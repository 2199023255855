// src/services/AuthService.js
import axios from 'axios'; // Use default Axios instance

// const API_BASE_URL = 'https://86ktfzke6h.execute-api.us-east-1.amazonaws.com/dev'; // dev
const API_BASE_URL = 'https://5qva06rx59.execute-api.us-east-1.amazonaws.com/prod'; // prod

class AuthService {
  constructor() {
    this.accessToken = null;
    this.idToken = null;
    this.refreshToken = null;
    this.refreshInProgress = false; // Flag to prevent multiple refresh attempts
    this.loadTokens(); // Load tokens on initialization
  }

  // Initialize tokens from localStorage
  loadTokens() {
    this.accessToken = localStorage.getItem('accessToken');
    this.idToken = localStorage.getItem('idToken');
    this.refreshToken = localStorage.getItem('refreshToken');
  }

  // Save tokens to localStorage
  saveTokens(tokens) {
    this.accessToken = tokens.accessToken;
    this.idToken = tokens.idToken;
    this.refreshToken = tokens.refreshToken || this.refreshToken; // Refresh token may not be returned every time
    localStorage.setItem('accessToken', this.accessToken);
    localStorage.setItem('idToken', this.idToken);
    localStorage.setItem('refreshToken', this.refreshToken);
  }

  // Clear tokens from localStorage
  clearTokens() {
    this.accessToken = null;
    this.idToken = null;
    this.refreshToken = null;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('refreshToken');
  }

  // Refresh tokens with a single refresh attempt
  async refreshTokens() {
    if (!this.refreshToken || this.refreshInProgress) {
      console.error('No refresh token available or refresh already in progress');
      this.clearTokens();
      return false;
    }

    this.refreshInProgress = true; // Set the flag to prevent multiple simultaneous refreshes

    try {
      const response = await axios.post(`${API_BASE_URL}/auth/refresh`, {
        idToken: this.idToken,
        refreshToken: this.refreshToken,
      });

      if (response.status === 200 && response.data.data) {
        this.saveTokens(response.data.data);
        return true;
      } else {
        this.clearTokens(); // Clear tokens on failure to prevent loops
        return false;
      }
    } catch (error) {
      console.error('Token refresh failed:', error);
      this.clearTokens();
      return false;
    } finally {
      this.refreshInProgress = false; // Clear the flag after refresh attempt
    }
  }

  // Get or refresh the access token
  async getAccessToken() {
    if (!this.accessToken) {
      const refreshed = await this.refreshTokens();
      if (!refreshed) throw new Error('Failed to refresh access token');
    }
    return this.accessToken;
  }

  // Login method to authenticate user and save tokens
  async login(email, password) {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/login`, {
        email,
        password,
      });

      if (response.status === 200 && response.data.data) {
        this.saveTokens(response.data.data); // Save tokens upon successful login
        return { success: true };
      } else {
        return { success: false, message: response.data.message || 'Login failed' };
      }
    } catch (error) {
      console.error("Login failed:", error);
      return { success: false, message: "Login failed due to network or server issue." };
    }
  }

  // Register method for user registration
  async register(email, password, name, organizationName) {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/register`, {
        email,
        password,
        name,
        organizationName,
      });

      if (response.status === 200) {
        return { success: true };
      } else {
        return { success: false, message: response.data.message || 'Registration failed' };
      }
    } catch (error) {
      console.error("Registration failed:", error);
      return { success: false, message: "Registration failed due to network or server issue." };
    }
  }

  // Confirm registration method to verify account
  async confirm(email, confirmationCode) {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/confirm`, {
        email,
        confirmationCode,
      });

      if (response.status === 200) {
        return { success: true };
      } else {
        return { success: false, message: response.data.message || 'Confirmation failed' };
      }
    } catch (error) {
      console.error("Confirmation failed:", error);
      return { success: false, message: "Confirmation failed due to network or server issue." };
    }
  }

  // Resend confirmation code to user
  async resend(email) {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/resend`, { email });

      if (response.status === 200) {
        return { success: true };
      } else {
        return { success: false, message: response.data.message || 'Resend failed' };
      }
    } catch (error) {
      console.error("Resend confirmation code failed:", error);
      return { success: false, message: "Resend failed due to network or server issue." };
    }
  }

  // Logout method to clear tokens and end session
  logout() {
    this.clearTokens();
  }

  // Check if authenticated by verifying existence of idToken
  isAuthenticated() {
    this.loadTokens(); // Ensure tokens are loaded from localStorage
    return !!this.idToken;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();
