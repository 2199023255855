// src/components/QuickActions.js
import React from 'react';
import { BookOpenIcon, KeyIcon, LinkIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const QuickActions = () => {
  const actions = [
    { name: 'Generate API Key', icon: KeyIcon, path: '/api-keys' },
    { name: 'Add Connector', icon: LinkIcon, path: '/connectors' },
    // { name: 'View Usage Metrics', icon: ChartBarIcon, path: '/usage-metrics' },
    { name: 'View API Call Logs', icon: ChartBarIcon, path: '/api-call-logs' },
	{ name: 'Read Documentation', icon: BookOpenIcon, path: '/docs' },
  ];

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
      <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-4">Quick Actions</h3>
      <div className="grid grid-cols-2 gap-4">
        {actions.map(action => (
          <Link
            key={action.name}
            to={action.path}
            className="flex items-center p-3 bg-gray-100 dark:bg-gray-700 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition"
          >
            <action.icon className="w-6 h-6 text-primary" />
            <span className="ml-2 text-sm font-medium text-gray-700 dark:text-gray-300">{action.name}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default QuickActions;
