import React, { createContext, useCallback, useState, useContext } from 'react';
import BillingService from '../services/BillingService';
import { LoadingContext } from './LoadingContext';

export const BillingContext = createContext();

export const BillingProvider = ({ children }) => {
  const [checkoutClientSecret, setCheckoutClientSecret] = useState(null);
  const [sessionStatus, setSessionStatus] = useState(null);
  const { showLoading, hideLoading } = useContext(LoadingContext);

  // Create a checkout session
  const createCheckoutSession = useCallback(async () => {
    showLoading();
    const result = await BillingService.createCheckoutSession();
    hideLoading();
    if (result.success) {
		console.log(`RESULT: ${JSON.stringify(result)}`);
		setCheckoutClientSecret(result.clientSecret);
      return result.clientSecret;
    } else {
      alert(result.message || 'Failed to create checkout session.');
    }
  }, [showLoading, hideLoading]);

  // Get the session status
  const getSessionStatus = useCallback(async (sessionId) => {
    showLoading();
    const result = await BillingService.getSessionStatus(sessionId);
    hideLoading();
    if (result.success) {
      setSessionStatus(result.status);
      return result;
    } else {
      alert(result.message || 'Failed to retrieve session status.');
    }
  }, [showLoading, hideLoading]);

  return (
    <BillingContext.Provider value={{ checkoutClientSecret, sessionStatus, createCheckoutSession, getSessionStatus }}>
      {children}
    </BillingContext.Provider>
  );
};
