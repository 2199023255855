// src/pages/SignUpPage.js
import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import googleIcon from '../assets/icons/google.svg';
import appleIcon from '../assets/icons/apple.svg';
import AuthService from '../services/AuthService';
import { LoadingContext } from '../contexts/LoadingContext'; // Import LoadingContext

const SignUpPage = ({ setIsAuthenticated }) => {
  const [fullName, setFullName] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Access showLoading and hideLoading from LoadingContext
  const { showLoading, hideLoading } = useContext(LoadingContext);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError('');
    try {
      showLoading(); // Show spinner
      const result = await AuthService.register(email, password, fullName, organizationName);
      hideLoading(); // Hide spinner

      if (result.success) {
        // Redirect to confirmation page with email as a parameter
        navigate('/confirm', { state: { email } });
      } else {
        setError(result.message || 'Registration failed.');
      }
    } catch (err) {
      hideLoading(); // Hide spinner
      setError('An error occurred during registration.');
    }
  };

  const handleGoogleSignUp = () => {
    showLoading(); // Show spinner if the sign-up process is asynchronous
    // Implement Google Sign-Up logic
    // After completing the process, ensure to hide the loading spinner
    // For demonstration, we'll use a timeout
    setTimeout(() => {
      hideLoading();
      alert('Google Sign-Up flow triggered');
      setIsAuthenticated(true);
    }, 1000);
  };

  const handleAppleSignUp = () => {
    showLoading(); // Show spinner if the sign-up process is asynchronous
    // Implement Apple Sign-Up logic
    // After completing the process, ensure to hide the loading spinner
    // For demonstration, we'll use a timeout
    setTimeout(() => {
      hideLoading();
      alert('Apple Sign-Up flow triggered');
      setIsAuthenticated(true);
    }, 1000);
  };

  return (
    <div className="min-h-screen flex">
      {/* Sign Up Form */}
      <div className="flex flex-col w-full lg:w-1/2 items-center justify-center p-8">
        <div className="max-w-md w-full space-y-8">
          <h2 className="text-3xl font-bold text-gray-100 mb-6">Create an Account</h2>
          <form onSubmit={handleSignUp} className="space-y-4">
            {error && <p className="text-red-500">{error}</p>}
            <div>
              <label className="block text-sm font-medium text-gray-100">Full Name</label>
              <input
                type="text"
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                className="mt-1 px-3 py-2 w-full border rounded-md text-gray-900 bg-gray-100 focus:outline-none focus:ring-primary"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-100">Organization Name</label>
              <input
                type="text"
                required
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
                className="mt-1 px-3 py-2 w-full border rounded-md text-gray-900 bg-gray-100 focus:outline-none focus:ring-primary"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-100">Email Address</label>
              <input
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 px-3 py-2 w-full border rounded-md text-gray-900 bg-gray-100 focus:outline-none focus:ring-primary"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-100">Password</label>
              <input
                type="password"
                required
                minLength="6"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 px-3 py-2 w-full border rounded-md text-gray-900 bg-gray-100 focus:outline-none focus:ring-primary"
              />
              <p className="text-xs text-gray-300 mt-1">Password must be at least 14 characters, 1 number, special character, upper & lowercase letter.</p>
            </div>
            <button
              type="submit"
              className="w-full py-2 bg-primary text-white rounded-md hover:bg-primary-dark transition"
            >
              Sign Up
            </button>
          </form>
          {/* <div className="flex items-center my-4">
            <hr className="flex-1" />
            <span className="px-4 text-gray-300">or continue with</span>
            <hr className="flex-1" />
          </div>
          <div className="flex space-x-4">
            <button
              onClick={handleGoogleSignUp}
              className="flex items-center justify-center px-4 py-2 w-full border border-gray-300 rounded-md hover:bg-gray-50 hover:text-black transition-colors"
            >
              <img src={googleIcon} alt="Google" className="w-5 h-5 mr-2" />
              Google
            </button>
            <button
              onClick={handleAppleSignUp}
              className="flex items-center justify-center px-4 py-2 w-full border border-gray-300 rounded-md hover:bg-gray-50 hover:text-black transition-colors"
            >
              <img src={appleIcon} alt="Apple" className="w-5 h-5 mr-2" />
              Apple
            </button>
          </div> */}
          <p className="text-center text-sm text-gray-300">
            Already have an account?{' '}
            <Link to="/signin" className="text-primary hover:underline">
              Sign In
            </Link>
          </p>
        </div>
      </div>
      {/* Right side with benefits or welcome message */}
      <div className="hidden lg:flex w-1/2 bg-primary items-center justify-center p-12">
        <div>
          <h1 className="text-4xl font-bold text-white mb-6">Join Fiscus</h1>
          <p className="text-lg text-gray-100">
            Streamline your API integrations with our powerful platform.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
