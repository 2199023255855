// src/components/Settings/UsageMeter.js
import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

const UsageMeter = ({ usageData }) => {
  const { apiCalls } = usageData;
  const freeApiCalls = 50000;

  // Calculate overage and tier-based costs
  const apiCallsOverage = Math.max(apiCalls - freeApiCalls, 0);

  const calculateCost = (apiCalls) => {
    if (apiCalls <= freeApiCalls) return 0; // Free plan
    if (apiCalls <= 2500000)
      return (apiCalls - freeApiCalls) * 0.0002; // First tier cost
    if (apiCalls <= 10000000)
      return (2500000 - freeApiCalls) * 0.0002 + (apiCalls - 2500000) * 0.00019; // Second tier cost
    return (
      (2500000 - freeApiCalls) * 0.0002 +
      (10000000 - 2500000) * 0.00019 +
      (apiCalls - 10000000) * 0.00018 // Third tier cost
    );
  };

  const apiCallsCost = calculateCost(apiCalls);
  const usagePercentage = Math.min((apiCalls / freeApiCalls) * 100, 100);

  const getProgressColor = () => {
    if (usagePercentage < 70) return 'bg-green-500';
    if (usagePercentage < 90) return 'bg-yellow-500';
    return 'bg-red-500';
  };

  const getStatusMessage = () => {
    if (apiCallsOverage > 0) {
      return {
        title: 'Over Usage',
        message: `You've exceeded the free limit by ${apiCallsOverage.toLocaleString()} API calls.`,
        button: {
          text: 'Upgrade Now',
          action: 'upgrade',
          color: 'bg-red-500',
        },
        bgColor: 'bg-red-50',
        textColor: 'text-red-700',
      };
    } else if (usagePercentage >= 80) {
      return {
        title: 'Almost There!',
        message: `You're nearing your free usage limit.`,
        button: {
          text: 'Upgrade Plan',
          action: 'upgrade',
          color: 'bg-yellow-500',
        },
        bgColor: 'bg-yellow-50',
        textColor: 'text-yellow-700',
      };
    } else {
      return {
        title: 'Good Job!',
        message: `You're within the free usage limits.`,
        button: null,
        bgColor: 'bg-green-50',
        textColor: 'text-green-700',
      };
    }
  };

  const status = getStatusMessage();

  const handleUpgrade = () => {
    if (status.button.action === 'upgrade') {
      // Determine current plan and act accordingly
      if (status.button.text === 'Upgrade Now') {
        // User is on Free plan, trigger Stripe checkout
        window.location.href = '/upgrade'; // This should be handled in BillingSettings
      } else if (status.button.text === 'Upgrade Plan') {
        // User is on Usage plan, console.log for now
        console.log('Usage plan user attempted to upgrade.');
        // Future logic: Send email or notify admin
      }
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mb-6">
      <div className="flex items-center justify-between mb-4">
        <h4 className="text-2xl font-semibold text-gray-800 dark:text-white">Current Usage</h4>
        <div className="flex items-center text-gray-500 dark:text-gray-400">
          <InformationCircleIcon className="w-5 h-5 mr-1" />
          <span className="text-sm">Updated now</span>
        </div>
      </div>

      <div className="mb-4">
        <div className="flex justify-between mb-2">
          <span className="text-md font-medium text-gray-700 dark:text-gray-300">API Calls</span>
          <span className="text-md text-gray-700 dark:text-gray-300">
            {apiCalls.toLocaleString()} / {freeApiCalls.toLocaleString()} Free
          </span>
        </div>
        <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-4">
          <div
            className={`${getProgressColor()} h-4 rounded-full`}
            style={{ width: `${usagePercentage}%` }}
          ></div>
        </div>
        <div className="flex justify-between text-sm mt-1">
          <span className="text-gray-600 dark:text-gray-400">0</span>
          <span className="text-gray-600 dark:text-gray-400">{freeApiCalls.toLocaleString()}</span>
        </div>
      </div>

      <div className={`${status.bgColor} p-4 rounded-lg`}>
        <h5 className={`text-lg font-semibold ${status.textColor} mb-2`}>{status.title}</h5>
        <p className={`text-sm ${status.textColor} mb-4`}>{status.message}</p>
        {status.button && (
          <button
            onClick={handleUpgrade}
            className={`${status.button.color} text-white px-4 py-2 rounded hover:bg-opacity-90 transition-opacity duration-200`}
          >
            {status.button.text}
          </button>
        )}
      </div>

      {apiCallsOverage > 0 && (
        <div className="mt-4">
          <p className="text-md text-gray-700 dark:text-gray-300">
            Total additional usage charges: <span className="font-semibold">${apiCallsCost.toFixed(2)}</span>.
          </p>
        </div>
      )}
    </div>
  );
};

export default UsageMeter;
