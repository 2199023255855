// src/components/Utilities/Sidebar.js
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  HomeIcon,
  KeyIcon,
  LinkIcon,
  ChartBarIcon,
  ClipboardDocumentListIcon,
  DocumentTextIcon,
  Cog6ToothIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/24/outline';
import { AuthContext } from '../../contexts/AuthContext';

const Sidebar = ({ sidebarOpen }) => {
  const location = useLocation();
  const { logout } = useContext(AuthContext);

  const menuItems = [
    { name: 'Dashboard', icon: HomeIcon, path: '/' },
    { name: 'API Keys', icon: KeyIcon, path: '/api-keys' },
    { name: 'Connectors', icon: LinkIcon, path: '/connectors' },
    // { name: 'Usage Metrics', icon: ChartBarIcon, path: '/usage-metrics' },
    { name: 'API Call Logs', icon: ClipboardDocumentListIcon, path: '/api-call-logs' },
    { name: 'Docs', icon: DocumentTextIcon, path: '/docs' },
    { name: 'Settings', icon: Cog6ToothIcon, path: '/settings' },
  ];

  return (
    <>
      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 overflow-hidden z-50 ${
          sidebarOpen ? 'w-64' : 'w-0'
        } transition-all duration-200`}
      >
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-center h-16 px-4 shadow-md">
            <h1 className="text-2xl font-bold text-primary">Fiscus</h1>
          </div>
          <nav className="flex-1 px-2 py-4 space-y-1 overflow-y-auto">
            {menuItems.map((item) => (
              <Link
                key={item.name}
                to={item.path}
                className={`flex items-center px-2 py-2 text-sm font-medium rounded-md ${
                  location.pathname === item.path
                    ? 'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white'
                    : 'text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
                }`}
              >
                <item.icon className="w-6 h-6 mr-3 text-primary flex-shrink-0" />
                <span className="truncate">{item.name}</span>
              </Link>
            ))}
          </nav>
          {/* Sign Out Button */}
          <div className="px-2 py-4">
            <button
              onClick={logout}
              className="flex items-center w-full px-2 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-200 dark:hover:bg-gray-700"
            >
              <ArrowDownTrayIcon className="w-6 h-6 mr-3 text-primary" />
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
