// src/components/ApiKeyManagement.js
import React, { useState, useContext } from 'react';
import { DocumentDuplicateIcon, TrashIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import ApiKeyModal from './ApiKeyModal';
import ApiKeyConfirmationModal from './ApiKeyConfirmationModal';
import { ApiKeyContext } from '../../contexts/ApiKeyContext'; // Import the ApiKeyContext

const ApiKeyManagement = () => {
  const { keys, createApiKey, revokeApiKey, regenerateApiKey } = useContext(ApiKeyContext); // Use the context
  const [showApiKeyModal, setShowApiKeyModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);
  const [activeTab, setActiveTab] = useState('production');

  const handleGenerateKey = async (newKeyData) => {
    await createApiKey(newKeyData.keyName, newKeyData.environment);
    setShowApiKeyModal(false); // Close modal after key generation
  };

  const handleRevokeKey = async () => {
    await revokeApiKey(selectedKey.apiKey);
    setShowConfirmationModal(false); // Close modal after revocation
    setSelectedKey(null); // Reset the selected key
  };

  const handleRegenerateKey = async () => {
    await regenerateApiKey(selectedKey.apiKey, selectedKey.keyName, selectedKey.environment);
    setShowConfirmationModal(false); // Close modal after regeneration
    setSelectedKey(null); // Reset the selected key
  };

  const handleCopyKey = (keyValue) => {
    navigator.clipboard.writeText(keyValue);
    alert('API Key copied to clipboard!');
  };

  if (keys === null) {
    return <div>Loading...</div>; // Show loading state if keys are not yet loaded
  }

  const filteredKeys = keys.filter((key) => key.environment === activeTab);

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold text-gray-800 dark:text-white">API Key Management</h3>
        <button
          onClick={() => setShowApiKeyModal(true)}
          className="flex items-center px-3 py-2 bg-primary text-white rounded hover:bg-primary-dark"
        >
          Generate New API Key
        </button>
      </div>
      <div className="mb-4">
        <div className="flex border-b border-gray-200 dark:border-gray-700">
          <button
            onClick={() => setActiveTab('production')}
            className={`px-4 py-2 -mb-px text-sm font-medium ${
              activeTab === 'production'
                ? 'text-primary border-b-2 border-primary'
                : 'text-gray-600 dark:text-gray-400 hover:text-primary'
            }`}
          >
            Production Keys
          </button>
          <button
            onClick={() => setActiveTab('sandbox')}
            className={`ml-4 px-4 py-2 -mb-px text-sm font-medium ${
              activeTab === 'sandbox'
                ? 'text-primary border-b-2 border-primary'
                : 'text-gray-600 dark:text-gray-400 hover:text-primary'
            }`}
          >
            Sandbox Keys
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-800">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">Name</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">Key</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">Status</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">Created At</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">Last Used</th>
              <th className="px-4 py-2 text-center text-sm font-medium text-gray-700 dark:text-gray-300">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredKeys.length === 0 ? (
              <tr>
                <td colSpan="6" className="px-4 py-2 text-center text-gray-500 dark:text-gray-400">
                  No API keys generated yet for {activeTab} environment.
                </td>
              </tr>
            ) : (
              filteredKeys.map((key) => (
                <tr key={key.apiKey} className="border-t border-gray-200 dark:border-gray-700">
                  <td className="px-4 py-2 text-sm text-gray-800 dark:text-gray-200">{key.keyName}</td>
                  <td className="px-4 py-2 text-sm text-gray-800 dark:text-gray-200 flex items-center">
                    <span className="font-mono">
                      {key.apiKey.substring(0, 4)}...{key.apiKey.substring(key.apiKey.length - 4)}
                    </span>
                    <DocumentDuplicateIcon
                      className="w-5 h-5 ml-2 text-gray-500 dark:text-gray-400 cursor-pointer hover:text-primary"
                      onClick={() => handleCopyKey(key.apiKey)}
                    />
                  </td>
                  <td className="px-4 py-2 text-sm">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        key.status === 'Active'
                          ? 'bg-green-100 text-green-800'
                          : 'bg-red-100 text-red-800'
                      }`}
                    >
                      {key.status}
                    </span>
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-800 dark:text-gray-200">{key.createdAt}</td>
                  <td className="px-4 py-2 text-sm text-gray-800 dark:text-gray-200">
                    {key.lastUsed ? key.lastUsed : 'Never'}
                  </td>
                  <td className="px-4 py-2 text-center">
                    {key.status === 'Active' && (
                      <div className="flex items-center justify-center space-x-2">
                        <button
                          onClick={() => {
                            setSelectedKey(key);
                            setConfirmationAction('revoke');
                            setShowConfirmationModal(true);
                          }}
                          className="text-red-500 hover:text-red-700"
                          title="Revoke Key"
                        >
                          <TrashIcon className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => {
                            setSelectedKey(key);
                            setConfirmationAction('regenerate');
                            setShowConfirmationModal(true);
                          }}
                          className="text-gray-500 hover:text-gray-700"
                          title="Regenerate Key"
                        >
                          <ArrowPathIcon className="w-5 h-5" />
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {/* Modals */}
      {showApiKeyModal && (
        <ApiKeyModal
          onClose={() => setShowApiKeyModal(false)}
          onGenerate={handleGenerateKey}
        />
      )}
      {showConfirmationModal && (
        <ApiKeyConfirmationModal
          action={confirmationAction}
          onConfirm={
            confirmationAction === 'revoke' ? handleRevokeKey : handleRegenerateKey
          }
          onCancel={() => {
            setShowConfirmationModal(false);
            setSelectedKey(null);
            setConfirmationAction(null);
          }}
        />
      )}
    </div>
  );
};

export default ApiKeyManagement;
