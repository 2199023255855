// src/components/OverviewCard.js
import React from 'react';

const OverviewCard = ({ title, value, icon, color }) => {
  const IconComponent = icon;
  return (
    <div className={`flex items-center p-4 bg-white dark:bg-gray-800 rounded-lg shadow`}>
      <div>
        <IconComponent className={`w-6 h-6 text-${color}-500`} /> {/* Just the colored icon */}
      </div>
      <div className="ml-4">
        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">{title}</p>
        <p className="text-xl font-semibold text-gray-800 dark:text-white">{value}</p>
      </div>
    </div>
  );
};

export default OverviewCard;
