// src/services/ApiKeyService.js
import axios from './axiosConfig';

class ApiKeyService {
  async getApiKeys() {
    try {
      const response = await axios.get('/apikeys');
      if (response.status === 200 && response.data.data) {
        return { success: true, data: response.data.data };
      } else {
        return { success: false, message: response.data.message || 'Failed to fetch API keys.' };
      }
    } catch (error) {
      console.log('Error fetching API keys:', error);
      return { success: false, message: 'Error fetching API keys.' };
    }
  }

  async createApiKey(keyName, environment) {
    try {
      const response = await axios.post('/apikeys', { keyName, environment });
      if (response.status === 200 && response.data.data) {
        return { success: true, data: response.data.data };
      } else {
        return { success: false, message: response.data.message || 'Failed to create API key.' };
      }
    } catch (error) {
      console.log('Error creating API key:', error);
      return { success: false, message: 'Error creating API key.' };
    }
  }

  async revokeApiKey(apiKey) {
    try {
      const response = await axios.delete(`/apikeys/${apiKey}`);
      if (response.status === 200) {
        return { success: true, message: response.data.message };
      } else {
        return { success: false, message: response.data.message || 'Failed to revoke API key.' };
      }
    } catch (error) {
      console.log('Error revoking API key:', error);
      return { success: false, message: 'Error revoking API key.' };
    }
  }

  async regenerateApiKey(apiKey, keyName, environment) {
    // Revoke old API key
    const revokeResponse = await this.revokeApiKey(apiKey);
    if (!revokeResponse.success) {
      return { success: false, message: 'Failed to revoke old API key.' };
    }
    // Create new API key
    const createResponse = await this.createApiKey(keyName, environment);
    if (!createResponse.success) {
      return { success: false, message: 'Failed to create new API key.' };
    }
    return { success: true, data: createResponse.data };
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ApiKeyService();
