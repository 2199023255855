// src/components/ApiCall/ApiCallLogDetails.js
import React, { useState, useEffect, useRef } from 'react';
import { DocumentDuplicateIcon, XMarkIcon } from '@heroicons/react/24/solid';

const ApiCallLogDetails = ({ log, onClose }) => {
  const [showRequest, setShowRequest] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const modalRef = useRef(null);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard!');
  };

  // Close modal when clicking outside of it
  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-auto">
      <div
        ref={modalRef}
        className="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-3xl max-h-full overflow-y-auto relative"
      >
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
        >
          <XMarkIcon className="w-6 h-6" />
        </button>
        <h3 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">
          API Call Details
        </h3>
        <div className="space-y-4">
          {/* General Info */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <span className="font-medium text-gray-700 dark:text-gray-300">
                API:
              </span>{' '}
              {log.connector}
            </div>
            <div>
              <span className="font-medium text-gray-700 dark:text-gray-300">
                Endpoint:
              </span>{' '}
              {log.operation}
            </div>
            <div>
              <span className="font-medium text-gray-700 dark:text-gray-300">
                Status:
              </span>{' '}
              {log.status}
            </div>
            <div>
              <span className="font-medium text-gray-700 dark:text-gray-300">
                Response Time:
              </span>{' '}
              {log.responseTime} ms
            </div>
            <div>
              <span className="font-medium text-gray-700 dark:text-gray-300">
                Timestamp:
              </span>{' '}
              {new Date(log.timestamp).toLocaleString()}
            </div>
          </div>

          {/* Request Details */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => setShowRequest(!showRequest)}
            >
              <h4 className="font-medium text-gray-800 dark:text-white mb-2">
                Request
              </h4>
              <span className="text-gray-500 dark:text-gray-400">
                {showRequest ? '▲' : '▼'}
              </span>
            </div>
            {showRequest && (
              <>
                <pre className="bg-gray-100 dark:bg-gray-700 p-4 rounded-md text-sm overflow-auto">
                  {JSON.stringify(log.requestPayload, null, 2)}
                </pre>
                <button
                  onClick={() =>
                    copyToClipboard(JSON.stringify(log.requestPayload, null, 2))
                  }
                  className="mt-2 px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200"
                >
                  <DocumentDuplicateIcon className="w-5 h-5 inline-block mr-2" />
                  Copy Request
                </button>
              </>
            )}
          </div>

          {/* Response Details */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => setShowResponse(!showResponse)}
            >
              <h4 className="font-medium text-gray-800 dark:text-white mb-2">
                Response
              </h4>
              <span className="text-gray-500 dark:text-gray-400">
                {showResponse ? '▲' : '▼'}
              </span>
            </div>
            {showResponse && (
              <>
                <pre className="bg-gray-100 dark:bg-gray-700 p-4 rounded-md text-sm overflow-auto">
                  {JSON.stringify(log.responsePayload, null, 2)}
                </pre>
                <button
                  onClick={() =>
                    copyToClipboard(JSON.stringify(log.responsePayload, null, 2))
                  }
                  className="mt-2 px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200"
                >
                  <DocumentDuplicateIcon className="w-5 h-5 inline-block mr-2" />
                  Copy Response
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiCallLogDetails;
