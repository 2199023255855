// src/services/DashboardService.js
import axios from './axiosConfig';

class DashboardService {
  async getDashboardData() {
    try {
      const response = await axios.get('/dashboard');
      if (response.status === 200 && response.data.data) {
        return { success: true, data: response.data.data };
      } else {
        return { success: false, message: response.data.message || 'Failed to fetch dashboard data.' };
      }
    } catch (error) {
      console.log('Error fetching dashboard data:', error);
      return { success: false, message: 'Error fetching dashboard data.' };
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DashboardService();
