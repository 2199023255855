// src/components/ApiKeyConfirmationModal.js
import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

const ApiKeyConfirmationModal = ({ action, onConfirm, onCancel }) => {
  const actionText = action === 'revoke' ? 'Revoke' : 'Regenerate';
  const description =
    action === 'revoke'
      ? 'Are you sure you want to revoke this API key? This action cannot be undone.'
      : 'Are you sure you want to regenerate this API key? The old key will no longer work.';

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-md w-full p-6">
        <div className="flex items-center mb-4">
          <ExclamationCircleIcon className="w-6 h-6 text-red-500" />
          <h2 className="ml-2 text-xl font-semibold text-gray-800 dark:text-white">
            {actionText} API Key
          </h2>
        </div>
        <p className="mb-4 text-gray-700 dark:text-gray-300">{description}</p>
        <div className="flex justify-end">
          <button
            onClick={onCancel}
            className="mr-2 px-4 py-2 bg-gray-200 dark:bg-gray-700 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className={`px-4 py-2 ${
              action === 'revoke' ? 'bg-red-600 hover:bg-red-700' : 'bg-primary hover:bg-primary-dark'
            } text-white rounded`}
          >
            {actionText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApiKeyConfirmationModal;
