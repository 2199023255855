// src/pages/CheckoutForm.js
import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import BillingService from '../services/BillingService'; // Import the BillingService directly

// Stripe publishable key
const stripePromise = loadStripe("pk_test_51QDHxDGHge80oT4hltLfrZ0nQK71BegoZ0IHAXM3TAjFtqfoh0khMHQ8IjBw57U0Ad2VIJMtNA8vp7Vd2JIILCei00NFEPGZzb");

const CheckoutForm = () => {
  const [checkoutClientSecret, setCheckoutClientSecret] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initiateCheckoutSession = async () => {
      setLoading(true);
      const result = await BillingService.createCheckoutSession();
      if (result.success) {
        setCheckoutClientSecret(result.clientSecret); // Set the client secret on success
      } else {
        console.log(result.message || 'Failed to create checkout session.');
      }
      setLoading(false);
    };

    initiateCheckoutSession(); // Call this as soon as the component mounts
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!checkoutClientSecret) {
    return <div>Failed to initiate checkout. Please try again later.</div>;
  }

  const options = { clientSecret: checkoutClientSecret }; // Directly use the client secret in options
  console.log(`OPTIONS: ${JSON.stringify(options)}`);

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default CheckoutForm;
