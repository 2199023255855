// src/contexts/ApiKeyContext.js
import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import ApiKeyService from '../services/ApiKeyService';
import { LoadingContext } from './LoadingContext';
import { AuthContext } from './AuthContext';

export const ApiKeyContext = createContext();

export const ApiKeyProvider = ({ children }) => {
  const [keys, setKeys] = useState(null);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { isAuthenticated } = useContext(AuthContext);

  const fetchApiKeys = useCallback(async () => {
    showLoading();
    const result = await ApiKeyService.getApiKeys();
    hideLoading();
    if (result.success) {
      setKeys(result.data);
    } else {
      alert(result.message || 'Failed to fetch API keys.');
    }
  }, [showLoading, hideLoading]);

  useEffect(() => {
    if (isAuthenticated && !keys) { // Only fetch if authenticated and keys are not already loaded
      fetchApiKeys();
    }
  }, [isAuthenticated, keys, fetchApiKeys]);

  const createApiKey = useCallback(async (keyName, environment) => {
    showLoading();
    const result = await ApiKeyService.createApiKey(keyName, environment);
    hideLoading();
    if (result.success) {
      await fetchApiKeys();
    } else {
      alert(result.message || 'Failed to create API key.');
    }
  }, [showLoading, hideLoading, fetchApiKeys]);

  const revokeApiKey = useCallback(async (apiKey) => {
    showLoading();
    const result = await ApiKeyService.revokeApiKey(apiKey);
    hideLoading();
    if (result.success) {
      await fetchApiKeys();
    } else {
      alert(result.message || 'Failed to revoke API key.');
    }
  }, [showLoading, hideLoading, fetchApiKeys]);

  const regenerateApiKey = useCallback(async (apiKey, keyName, environment) => {
    showLoading();
    const result = await ApiKeyService.regenerateApiKey(apiKey, keyName, environment);
    hideLoading();
    if (result.success) {
      await fetchApiKeys();
    } else {
      alert(result.message || 'Failed to regenerate API key.');
    }
  }, [showLoading, hideLoading, fetchApiKeys]);

  return (
    <ApiKeyContext.Provider value={{ keys, createApiKey, revokeApiKey, regenerateApiKey, fetchApiKeys }}>
      {children}
    </ApiKeyContext.Provider>
  );
};
