// src/contexts/DashboardContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import DashboardService from '../services/DashboardService';
import { LoadingContext } from './LoadingContext';
import { AuthContext } from './AuthContext';

export const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [fetchStatus, setFetchStatus] = useState('idle'); // Track fetch status: 'idle' | 'fetching' | 'fetched' | 'failed'
  const [lastFetchTime, setLastFetchTime] = useState(0); // Timestamp of last fetch
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { isAuthenticated } = useContext(AuthContext);

  // Minimum interval between fetches in milliseconds (1 minute)
  const FETCH_INTERVAL = 60000;

  const fetchDashboardData = async () => {
    // Throttle: Only allow fetch if enough time has passed since the last attempt
    const currentTime = Date.now();
    if (currentTime - lastFetchTime < FETCH_INTERVAL) {
      console.warn("Throttled fetch attempt blocked to prevent excessive requests.");
      return;
    }

    // Update fetch status and last fetch time
    setFetchStatus('fetching');
    setLastFetchTime(currentTime);

    try {
      if (showLoading) showLoading();
      const result = await DashboardService.getDashboardData();

      if (result.success) {
        setDashboardData(result.data);
        setFetchStatus('fetched');
      } else {
        setFetchStatus('failed');
      }
    } catch (error) {
      setFetchStatus('failed');
    } finally {
      if (hideLoading) hideLoading();
    }
  };

  // Trigger fetch on authentication if idle, only once per session
  useEffect(() => {
    if (isAuthenticated && fetchStatus === 'idle') {
      fetchDashboardData();
    }
  }, [isAuthenticated, fetchStatus]);

  return (
    <DashboardContext.Provider value={{ dashboardData, fetchStatus }}>
      {children}
    </DashboardContext.Provider>
  );
};
