// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider } from './components/Utilities/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import { LoadingProvider } from './contexts/LoadingContext';
import { ApiKeyProvider } from './contexts/ApiKeyContext';
import { ApiLogProvider } from './contexts/ApiLogContext';
import { DashboardProvider } from './contexts/DashboardContext';
import { BillingProvider } from './contexts/BillingContext';
import { ConnectorsProvider } from './contexts/ConnectorsContext'; // Add ConnectorsProvider
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthProvider>
        <LoadingProvider>
          <DashboardProvider>
            <ApiKeyProvider>
              <ApiLogProvider>
                <BillingProvider>
                  <ConnectorsProvider>
                    <App />
                  </ConnectorsProvider>
                </BillingProvider>
              </ApiLogProvider>
            </ApiKeyProvider>
          </DashboardProvider>
        </LoadingProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);
