// src/components/ApiCall/LogFilters.js
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

const LogFilters = ({ onFilterChange, onRefresh }) => {
	const [status, setStatus] = useState('All');
	const [api, setApi] = useState('');
	const [endpoint, setEndpoint] = useState('');
	const [dateRange, setDateRange] = useState({ from: null, to: null });
  
	// Event handlers
	const handleStatusChange = (e) => {
	  const newStatus = e.target.value;
	  setStatus(newStatus);
	  onFilterChange({ status: newStatus, api, endpoint, dateRange });
	};
  
	const handleApiChange = (e) => {
	  const newApi = e.target.value;
	  setApi(newApi);
	  onFilterChange({ status, api: newApi, endpoint, dateRange });
	};
  
	const handleEndpointChange = (e) => {
	  const newEndpoint = e.target.value;
	  setEndpoint(newEndpoint);
	  onFilterChange({ status, api, endpoint: newEndpoint, dateRange });
	};
  
	const handleDateRangeChange = (fromDate, toDate) => {
	  const newDateRange = { from: fromDate, to: toDate };
	  setDateRange(newDateRange);
	  onFilterChange({ status, api, endpoint, dateRange: newDateRange });
	};
  
	return (
	  <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-6 w-full mb-6 relative">
		{/* Refresh Button */}
		<button
		  onClick={onRefresh}
		  className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100"
		  title="Refresh Logs"
		>
		  <ArrowPathIcon className="w-6 h-6" />
		</button>
  
		<h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-4">Filter Logs</h3>
		<div className="grid grid-cols-1 md:grid-cols-4 gap-4">
		  {/* Status Filter */}
		  <div>
			<label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
			  Status
			</label>
			<select
			  value={status}
			  onChange={handleStatusChange}
			  className="w-full px-3 py-2 border rounded-md bg-gray-100 dark:bg-gray-700 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary"
			>
			  <option value="All">All</option>
			  <option value="2xx">2xx</option>
			  <option value="4xx">4xx</option>
			  <option value="5xx">5xx</option>
			</select>
		  </div>
  
		  {/* API Filter */}
		  <div>
			<label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
			  API
			</label>
			<input
			  type="text"
			  value={api}
			  onChange={handleApiChange}
			  placeholder="Search API"
			  className="w-full px-3 py-2 border rounded-md bg-gray-100 dark:bg-gray-700 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary"
			/>
		  </div>
  
		  {/* Endpoint Filter */}
		  <div>
			<label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
			  Endpoint
			</label>
			<input
			  type="text"
			  value={endpoint}
			  onChange={handleEndpointChange}
			  placeholder="Search Endpoint"
			  className="w-full px-3 py-2 border rounded-md bg-gray-100 dark:bg-gray-700 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary"
			/>
		  </div>
  
		  {/* Date Range Filter */}
		  <div className="flex flex-col">
			<label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
			  Date Range
			</label>
			<div className="flex space-x-2">
			  <DatePicker
				selected={dateRange.from}
				onChange={(date) => handleDateRangeChange(date, dateRange.to)}
				selectsStart
				startDate={dateRange.from}
				endDate={dateRange.to}
				placeholderText="From"
				className="w-full px-3 py-2 border rounded-md bg-gray-100 dark:bg-gray-700 dark:text-white"
			  />
			  <DatePicker
				selected={dateRange.to}
				onChange={(date) => handleDateRangeChange(dateRange.from, date)}
				selectsEnd
				startDate={dateRange.from}
				endDate={dateRange.to}
				minDate={dateRange.from}
				placeholderText="To"
				className="w-full px-3 py-2 border rounded-md bg-gray-100 dark:bg-gray-700 dark:text-white"
			  />
			</div>
		  </div>
		</div>
	  </div>
	);
  };
  
  export default LogFilters;