// src/contexts/ConnectorsContext.js
import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import ConnectorsService from '../services/ConnectorsService';
import { LoadingContext } from './LoadingContext';
import { AuthContext } from './AuthContext';

export const ConnectorsContext = createContext();

export const ConnectorsProvider = ({ children }) => {
  const [connectors, setConnectors] = useState(() => {
    const cachedConnectors = sessionStorage.getItem('connectors');
    return cachedConnectors ? JSON.parse(cachedConnectors) : [];
  });
  const [operations, setOperations] = useState({});
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { isAuthenticated } = useContext(AuthContext);

  // Track whether connectors were loaded initially in this session
  const [initialLoad, setInitialLoad] = useState(false);

  // Fetch connectors from API and cache them in sessionStorage
  const fetchConnectors = useCallback(async () => {
    showLoading();
    try {
      const result = await ConnectorsService.getConnectors();
      if (result.success && Array.isArray(result.data)) {
        setConnectors(result.data);
        sessionStorage.setItem('connectors', JSON.stringify(result.data));
      } else {
        console.log(result.message || 'Failed to fetch connectors.');
        setConnectors([]); // Set an empty array if fetch fails
      }
    } catch (error) {
      console.log('Error fetching connectors:', error);
      setConnectors([]);
    } finally {
      hideLoading();
    }
  }, [showLoading, hideLoading]);

  // Fetch operations for a specific connector
  const fetchConnectorOperations = useCallback(
    async (connectorName) => {
      if (!connectorName) return;
      showLoading();
      try {
        const result = await ConnectorsService.getConnectorOperations(connectorName);
        if (result.success) {
          setOperations((prevOperations) => ({
            ...prevOperations,
            [connectorName]: result.data.operations,
          }));
        } else {
          console.log(result.message || 'Failed to fetch connector operations.');
        }
      } catch (error) {
        console.log(`Error fetching operations for ${connectorName}:`, error);
      } finally {
        hideLoading();
      }
    },
    [showLoading, hideLoading]
  );

  // Add a connector to the organization
  const addConnectorToOrg = useCallback(async (connectorName, category, configurations = []) => {
    showLoading();
    try {
      const result = await ConnectorsService.addConnector(connectorName, category, configurations);
      if (result.success) {
        const newConnector = result.data;
        setConnectors((prevConnectors) => [...prevConnectors, newConnector]);
        sessionStorage.setItem('connectors', JSON.stringify([...connectors, newConnector]));
        alert('Connector added successfully.');
      } else {
        console.log(result.message || 'Failed to add connector.');
      }
    } catch (error) {
      console.log(`Error adding connector to organization: ${error}`);
    } finally {
      hideLoading();
    }
  }, [connectors, showLoading, hideLoading]);

  // Perform the initial load of connectors only once per session when authenticated
  useEffect(() => {
    if (isAuthenticated && !initialLoad) {
      fetchConnectors().then(() => setInitialLoad(true));
    }
  }, [isAuthenticated, initialLoad, fetchConnectors]);

  // Clear connectors from session storage and state
  const clearConnectorsCache = () => {
    setConnectors([]);
    sessionStorage.removeItem('connectors');
  };

  return (
    <ConnectorsContext.Provider
      value={{
        connectors,
        setConnectors,
        fetchConnectors,
        fetchConnectorOperations,
        addConnectorToOrg,
        operations,
        clearConnectorsCache,
      }}
    >
      {children}
    </ConnectorsContext.Provider>
  );
};
