// src/pages/DashboardHome.js
import React, { useContext } from 'react';
import OverviewCard from '../components/Utilities/OverviewCard';
import QuickActions from '../components/Dashboard/QuickActions';
import ApiCallLogs from '../components/Dashboard/ApiCallLogs';
import { ChartPieIcon, LinkIcon, KeyIcon } from '@heroicons/react/24/solid';
import { DashboardContext } from '../contexts/DashboardContext';
import { ApiKeyContext } from '../contexts/ApiKeyContext';

const DashboardPage = () => {
  const { dashboardData, fetchStatus } = useContext(DashboardContext);
  const { keys } = useContext(ApiKeyContext);

  if (fetchStatus === 'idle' || fetchStatus === 'fetching') {
    return <div>Loading...</div>;
  }

  if (fetchStatus === 'failed') {
    return <div>Error loading dashboard data. Please try again later.</div>;
  }

  const { organization, recentLogs = [] } = dashboardData; // Add fallback to an empty array if recentLogs is undefined

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      {/* Overview Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
        <OverviewCard
          title="Total API Calls"
          value={organization.totalApiUsage}
          icon={ChartPieIcon}
          color="blue"
        />
        <OverviewCard
          title="Active Connectors"
          value={organization.connectors ? organization.connectors.length : 0}
          icon={LinkIcon}
          color="green"
        />
        <OverviewCard
          title="API Keys"
          value={keys ? keys.length : 0}
          icon={KeyIcon}
          color="yellow"
        />
      </div>

      {/* Quick Actions */}
      <div className="mb-6">
        <QuickActions />
      </div>

      {/* Recent API Call Logs */}
      <ApiCallLogs logs={recentLogs} /> {/* Pass recentLogs (defaults to empty array) to avoid errors */}
    </div>
  );
};

export default DashboardPage;
