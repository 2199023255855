// src/services/ApiLogService.js
import axios from './axiosConfig';

class ApiLogService {
  constructor() {
    this.lastFetch = 0; // Timestamp for the last successful fetch
  }

  async getApiLogs(params = {}) {
    const now = Date.now();
    if (now - this.lastFetch < 2000) { // Throttle requests to once every 2 seconds
      return { success: false, message: 'Too many requests. Please wait.' };
    }

    try {
      const response = await axios.get('/logs', { params });
      if (response.status === 200 && response.data) {
        this.lastFetch = now; // Update last fetch timestamp
        return { success: true, data: response.data.data };
      } else {
        return { success: false, message: response.data.message || 'Failed to fetch API logs.' };
      }
    } catch (error) {
      console.log('Error fetching API logs:', error);
      return { success: false, message: 'Error fetching API logs.' };
    }
  }

  async getApiLogDetails(timestamp) {
    try {
      const response = await axios.get(`/logs/${encodeURIComponent(timestamp)}`);
      if (response.status === 200 && response.data) {
        return { success: true, data: response.data.data };
      } else {
        return { success: false, message: response.data.message || 'Failed to fetch API log details.' };
      }
    } catch (error) {
      console.log('Error fetching API log details:', error);
      return { success: false, message: 'Error fetching API log details.' };
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ApiLogService();
