// src/components/Connector/ConnectorSearch.js
import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

const ConnectorSearch = ({ onSearch }) => {
  const handleChange = (e) => {
    onSearch(e.target.value.trim());
  };

  return (
    <div className="relative w-full lg:w-1/2">
      <MagnifyingGlassIcon className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
      <input
        type="text"
        onChange={handleChange}
        placeholder="Search connectors..."
        className="w-full pl-10 pr-4 py-2 border rounded-md bg-gray-100 dark:bg-gray-700 dark:text-white focus:ring-2 focus:ring-primary transition"
      />
    </div>
  );
};

export default ConnectorSearch;
