// src/services/ConnectorsService.js
import axios from './axiosConfig';

class ConnectorsService {
  async getConnectors() {
    try {
      const response = await axios.get('/orgs/connectors');
      if (response.status === 200 && response.data.data) {
        return { success: true, data: response.data.data };
      } else {
        return { success: false, message: response.data.message || 'Failed to fetch connectors.' };
      }
    } catch (error) {
      console.log('Error fetching connectors:', error);
      return { success: false, message: 'Error fetching connectors.' };
    }
  }

  async getConnectorOperations(connectorName) {
    try {
      const response = await axios.get(`/connectors/operations?connectorName=${connectorName}`);
      if (response.status === 200 && response.data.data) {
        return { success: true, data: response.data.data };
      } else {
        return { success: false, message: response.data.message || 'Failed to fetch connector operations.' };
      }
    } catch (error) {
      console.log('Error fetching connector operations:', error);
      return { success: false, message: 'Error fetching connector operations.' };
    }
  }

  // New method to add a connector for an organization
  async addConnector(connectorName, category, configurations = []) {
    try {
      const response = await axios.post(`/orgs/connectors/${connectorName}`, {
        category,
        configurations
      });
      if (response.status === 200 || response.status === 201) {
        return { success: true, data: response.data.connector };
      } else {
        return { success: false, message: response.data.message || 'Failed to add connector.' };
      }
    } catch (error) {
      console.log(`Error adding connector ${connectorName}:`, error);
      return { success: false, message: 'Error adding connector.' };
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ConnectorsService();
