// src/components/Dashboard/ApiCallLogs.js
import React, { useState } from 'react';
import { MagnifyingGlassIcon, ArrowPathIcon, EyeIcon } from '@heroicons/react/24/solid';
import ApiCallLogDetails from '../ApiCall/ApiCallLogDetails';

const ApiCallLogs = ({ logs }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLog, setSelectedLog] = useState(null);

  const filteredLogs = logs.filter((log) =>
    log.connector.toLowerCase().includes(searchTerm.toLowerCase()) ||
    log.operation.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const refreshLogs = () => {
    // Implement refresh logic if needed
    setSearchTerm('');
  };

  const viewDetails = (log) => {
    setSelectedLog(log);
  };

  const closeModal = () => {
    setSelectedLog(null);
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
      {/* Title for Recent API Calls */}
      <div className="mb-4">
        <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Recent API Calls</h3>
      </div>

      {/* Search Bar */}
      <div className="flex items-center mb-4">
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <MagnifyingGlassIcon className="w-5 h-5 text-gray-400" />
          </div>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search API or Endpoint"
            className="w-full pl-10 pr-4 py-2 border rounded-md bg-gray-100 dark:bg-gray-700 dark:text-white focus:ring-2 focus:ring-primary"
          />
        </div>
      </div>

      {/* Logs Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-800">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">API</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">Operation</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">Status</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">Response Time</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">Timestamp</th>
              <th className="px-4 py-2 text-center text-sm font-medium text-gray-700 dark:text-gray-300">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredLogs.length === 0 ? (
              <tr>
                <td colSpan="6" className="px-4 py-2 text-center text-gray-500 dark:text-gray-400">
                  No logs found.
                </td>
              </tr>
            ) : (
              filteredLogs.map((log, index) => (
                <tr
                  key={index}
                  className="border-t border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <td className="px-4 py-2 text-sm text-gray-800 dark:text-gray-200">{log.connector}</td>
                  <td className="px-4 py-2 text-sm text-gray-800 dark:text-gray-200">{log.operation}</td>
                  <td className="px-4 py-2 text-sm">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        Number(log.status) >= 200 && Number(log.status) < 300
                          ? 'bg-green-100 text-green-800'
                          : Number(log.status) >= 400 && Number(log.status) < 500
                          ? 'bg-yellow-100 text-yellow-800'
                          : 'bg-red-100 text-red-800'
                      }`}
                    >
                      {log.status}
                    </span>
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-800 dark:text-gray-200">
                    {log.responseTime} ms
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-800 dark:text-gray-200">
                    {new Date(log.timestamp).toLocaleString()}
                  </td>
                  <td className="px-4 py-2 text-center">
                    <button
                      onClick={() => viewDetails(log)}
                      className="text-blue-500 hover:text-blue-700"
                      title="View Details"
                    >
                      <EyeIcon className="w-5 h-5" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* ApiCallLogDetails Modal */}
      {selectedLog && <ApiCallLogDetails log={selectedLog} onClose={closeModal} />}
    </div>
  );
};

export default ApiCallLogs;
