import React, { useState, useEffect, useCallback, useContext } from 'react';
import LogFilters from '../components/ApiCall/LogFilters';
import ApiCallLogList from '../components/ApiCall/ApiCallLogList';
import LogsOverview from '../components/ApiCall/LogsOverview';
import ApiLogService from '../services/ApiLogService';
import { ApiLogContext } from '../contexts/ApiLogContext';

const ApiCallLogsPage = () => {
  const { logs, addLogs, resetLogs, lastEvaluatedKey, isLoading, setIsLoading } = useContext(ApiLogContext);
  const [filters, setFilters] = useState({});
  const [filteredLogs, setFilteredLogs] = useState([]);

  const logsPerPage = 20;

  const fetchLogs = useCallback(
    async (reset = false) => {
      if (reset) {
        resetLogs();
      }
      setIsLoading(true);
      const params = { limit: logsPerPage };

      if (!reset && lastEvaluatedKey) {
        params.lastEvaluatedKey = lastEvaluatedKey;
      }

      try {
        const result = await ApiLogService.getApiLogs(params);
        if (result.success) {
          const newLogs = result.data.items || [];
          addLogs(newLogs, result.data.lastEvaluatedKey || null);
        } else {
			console.log(result.message || 'Failed to fetch API logs.');
        }
      } catch (error) {
        console.log('Error fetching API logs:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [addLogs, resetLogs, setIsLoading] // Removed lastEvaluatedKey
  );

  useEffect(() => {
	if (logs.length === 0) {
	  fetchLogs(true);
	}
  }, [fetchLogs, logs.length]);
  

  // Fetch logs only once when the component mounts
//   useEffect(() => {
//     if (logs.length === 0) {
//       fetchLogs(true);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []); // Empty dependency array

  // Apply filters whenever logs or filters change
  useEffect(() => {
    applyFilters();
  }, [logs, filters]);

  const applyFilters = useCallback(() => {
    let filtered = [...logs];

    // Status filter
    if (filters.status && filters.status !== 'All') {
      if (filters.status === '2xx') {
        filtered = filtered.filter(
          (log) => Number(log.status) >= 200 && Number(log.status) < 300
        );
      } else if (filters.status === '4xx') {
        filtered = filtered.filter(
          (log) => Number(log.status) >= 400 && Number(log.status) < 500
        );
      } else if (filters.status === '5xx') {
        filtered = filtered.filter((log) => Number(log.status) >= 500);
      }
    }

    // API filter
    if (filters.api && filters.api.trim() !== '') {
      filtered = filtered.filter((log) =>
        log.connector.toLowerCase().includes(filters.api.trim().toLowerCase())
      );
    }

    // Endpoint filter
    if (filters.endpoint && filters.endpoint.trim() !== '') {
      filtered = filtered.filter((log) =>
        log.operation.toLowerCase().includes(filters.endpoint.trim().toLowerCase())
      );
    }

    // Date Range filter
    if (filters.dateRange) {
      const { from, to } = filters.dateRange;
      if (from) {
        filtered = filtered.filter((log) => new Date(log.timestamp) >= from);
      }
      if (to) {
        filtered = filtered.filter((log) => new Date(log.timestamp) <= to);
      }
    }

    setFilteredLogs(filtered);
  }, [logs, filters]);

  const handleFilterChange = useCallback((newFilters) => {
    setFilters(newFilters);
  }, []);

  const handleRefresh = () => {
    resetLogs();
    fetchLogs(true);
  };

  const handleLoadMore = () => {
    if (!isLoading && lastEvaluatedKey) {
      fetchLogs();
    }
  };

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <h2 className="text-3xl font-semibold text-gray-800 dark:text-white mb-8">API Call Logs</h2>
      <LogsOverview logs={filteredLogs} isLoading={isLoading} />
      <LogFilters onFilterChange={handleFilterChange} onRefresh={handleRefresh} />
      <ApiCallLogList
        logs={filteredLogs}
        isLoading={isLoading}
        onLoadMore={handleLoadMore}
        hasMore={!!lastEvaluatedKey}
      />
    </div>
  );
};

export default ApiCallLogsPage;
