// src/pages/SettingsPage.js
import React from 'react';
import ProfileSettings from '../components/Settings/ProfileSettings';
import SecuritySettings from '../components/Settings/SecuritySettings';
import BillingSettings from '../components/Settings/BillingSettings';
import DeleteAccount from '../components/Settings/DeleteAccount';

const SettingsPage = () => (
  <div className="space-y-8">
    {/* <ProfileSettings /> */}
    {/* <SecuritySettings /> */}
    <BillingSettings />
    {/* <DeleteAccount /> */}
  </div>
);

export default SettingsPage;
