import axios from './axiosConfig';

class BillingService {
  async createCheckoutSession() {
    try {
      const response = await axios.post('/checkout-session');
      // Updated to correctly handle the response data format
      if (response.status === 200 && response.data.data.clientSecret) {
        return { success: true, clientSecret: response.data.data.clientSecret };
      } else {
        return { success: false, message: response.data.message || 'Failed to create checkout session.' };
      }
    } catch (error) {
      console.log('Error creating checkout session:', error);
      return { success: false, message: 'Error creating checkout session.' };
    }
  }

  async getSessionStatus(sessionId) {
    try {
      const response = await axios.get(`/session-status?session_id=${sessionId}`);
      if (response.status === 200 && response.data.data.status) {
        return { success: true, status: response.data.data.status, customerEmail: response.data.data.customerEmail };
      } else {
        return { success: false, message: response.data.message || 'Failed to retrieve session status.' };
      }
    } catch (error) {
      console.log('Error retrieving session status:', error);
      return { success: false, message: 'Error retrieving session status.' };
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new BillingService();
