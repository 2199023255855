// src/contexts/ApiLogContext.js
import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import ApiLogService from '../services/ApiLogService';
import { LoadingContext } from './LoadingContext';
import { AuthContext } from './AuthContext';

export const ApiLogContext = createContext();

export const ApiLogProvider = ({ children }) => {
  const [logs, setLogs] = useState(() => {
    const cachedLogs = sessionStorage.getItem('apiLogs');
    return cachedLogs ? JSON.parse(cachedLogs) : [];
  });
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(() => sessionStorage.getItem('apiLogsLastKey') || null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFetched, setHasFetched] = useState(false); // Flag to control initial fetch
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { isAuthenticated } = useContext(AuthContext);

  // Fetch logs with session caching
  const fetchLogs = useCallback(async (reset = false) => {
    if (isLoading || hasFetched) return; // Prevent re-fetch if already fetching or has fetched

    setIsLoading(true);
    showLoading();

    if (reset) {
      setLogs([]);
      setLastEvaluatedKey(null);
      sessionStorage.removeItem('apiLogs');
      sessionStorage.removeItem('apiLogsLastKey');
    }

    const params = reset ? { limit: 20 } : { lastEvaluatedKey, limit: 20 };

    try {
      const result = await ApiLogService.getApiLogs(params);
      if (result.success) {
        const newLogs = result.data.items || [];
        const updatedLogs = reset ? newLogs : [...logs, ...newLogs];
        setLogs(updatedLogs);
        setLastEvaluatedKey(result.data.lastEvaluatedKey || null);

        // Cache logs and lastEvaluatedKey in sessionStorage
        sessionStorage.setItem('apiLogs', JSON.stringify(updatedLogs));
        sessionStorage.setItem('apiLogsLastKey', result.data.lastEvaluatedKey || '');
        setHasFetched(true); // Mark as fetched
      } else {
        console.log(result.message || 'Failed to fetch API logs.');
      }
    } catch (error) {
      console.log('Error fetching API logs:', error);
    } finally {
      setIsLoading(false);
      hideLoading();
    }
  }, [isLoading, lastEvaluatedKey, showLoading, hideLoading, logs, hasFetched]);

  // Function to initialize fetch logs only once after authentication
  const initializeFetchLogs = useCallback(() => {
    if (isAuthenticated && !hasFetched) {
      fetchLogs(true);
    }
  }, [isAuthenticated, hasFetched, fetchLogs]);

  // Call initializeFetchLogs only once when the component mounts
  useEffect(() => {
    initializeFetchLogs();
  }, []); // Empty dependency array to ensure this runs only once

  const addLogs = (newLogs, newLastEvaluatedKey) => {
    const updatedLogs = [...logs, ...newLogs];
    setLogs(updatedLogs);
    setLastEvaluatedKey(newLastEvaluatedKey);

    // Update sessionStorage
    sessionStorage.setItem('apiLogs', JSON.stringify(updatedLogs));
    sessionStorage.setItem('apiLogsLastKey', newLastEvaluatedKey || '');
  };

  const resetLogs = () => {
    setLogs([]);
    setLastEvaluatedKey(null);
    setHasFetched(false); // Reset fetched flag
    sessionStorage.removeItem('apiLogs');
    sessionStorage.removeItem('apiLogsLastKey');
  };

  return (
    <ApiLogContext.Provider
      value={{
        logs,
        addLogs,
        resetLogs,
        lastEvaluatedKey,
        isLoading,
        fetchLogs,
      }}
    >
      {children}
    </ApiLogContext.Provider>
  );
};
