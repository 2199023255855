// src/components/ApiCall/ApiCallLogList.js
import React, { useState } from 'react';
import ApiCallLogDetails from './ApiCallLogDetails';
import { EyeIcon } from '@heroicons/react/24/solid';

const ApiCallLogList = ({ logs, isLoading, onLoadMore, hasMore }) => {
  const [selectedLog, setSelectedLog] = useState(null);

  const viewDetails = (log) => {
    setSelectedLog(log);
  };

  const closeModal = () => {
    setSelectedLog(null);
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-6">
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-800 rounded-lg">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                API
              </th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                Endpoint
              </th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                Status
              </th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                Response Time
              </th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">
                Timestamp
              </th>
              <th className="px-4 py-2 text-center text-sm font-medium text-gray-700 dark:text-gray-300">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
			{logs.length === 0 ? (
				<tr>
				<td
					colSpan="6"
					className="px-4 py-2 text-center text-gray-500 dark:text-gray-400"
				>
					{isLoading ? 'Loading logs...' : 'No logs found.'}
				</td>
				</tr>
			) : (
				logs.map((log, index) => (
				<tr
					key={index}
					className="border-t border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700"
				>
					<td className="px-4 py-2 text-sm text-gray-800 dark:text-gray-200">
					{log.connector}
					</td>
					<td className="px-4 py-2 text-sm text-gray-800 dark:text-gray-200">
					{log.operation}
					</td>
					<td className="px-4 py-2 text-sm">
					<span
						className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
						Number(log.status) >= 200 && Number(log.status) < 300
							? 'bg-green-100 text-green-800'
							: Number(log.status) >= 400 && Number(log.status) < 500
							? 'bg-yellow-100 text-yellow-800'
							: 'bg-red-100 text-red-800'
						}`}
					>
						{log.status}
					</span>
					</td>
					<td className="px-4 py-2 text-sm text-gray-800 dark:text-gray-200">
					{log.responseTime} ms
					</td>
					<td className="px-4 py-2 text-sm text-gray-800 dark:text-gray-200">
					{new Date(log.timestamp).toLocaleString()}
					</td>
					<td className="px-4 py-2 text-center">
					<button
						onClick={() => viewDetails(log)}
						className="text-blue-500 hover:text-blue-700"
						title="View Details"
					>
						<EyeIcon className="w-5 h-5" />
					</button>
					</td>
				</tr>
				))
			)}
		</tbody>
        </table>

        {/* Load More Button */}
        {hasMore && (
          <div className="flex justify-center mt-4">
            <button
              onClick={onLoadMore}
              className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark transition-colors duration-200"
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}
      </div>

      {/* ApiCallLogDetails Modal */}
      {selectedLog && (
        <ApiCallLogDetails log={selectedLog} onClose={closeModal} />
      )}
    </div>
  );
};

export default ApiCallLogList;
