// src/pages/SignInPage.js
import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import googleIcon from '../assets/icons/google.svg';
import appleIcon from '../assets/icons/apple.svg';
import { AuthContext } from '../contexts/AuthContext';
import { LoadingContext } from '../contexts/LoadingContext'

const SignInPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useContext(AuthContext);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError('');

    try {
      showLoading(); // Show spinner
      const result = await login(email, password);
      hideLoading(); // Hide spinner

      if (result.success) {
        navigate('/'); // Redirect to dashboard
      } else {
        setError(result.message || 'Login failed.');
      }
    } catch (err) {
      hideLoading(); // Hide spinner
      setError('An error occurred during login.');
    }
  };

  const handleGoogleSignIn = () => {
    // Implement Google Sign-In logic
    alert('Google Sign-In flow triggered');
    // Handle authentication state if necessary
  };

  const handleAppleSignIn = () => {
    // Implement Apple Sign-In logic
    alert('Apple Sign-In flow triggered');
    // Handle authentication state if necessary
  };

  return (
    <div className="min-h-screen flex">
      {/* Sign In Form */}
      <div className="flex flex-col w-full lg:w-1/2 items-center justify-center p-8">
        <div className="max-w-md w-full space-y-8">
          <h2 className="text-3xl font-bold text-gray-100 mb-6">Sign In</h2>
          <form onSubmit={handleSignIn} className="space-y-4">
            {error && <p className="text-red-500">{error}</p>}
            <div>
              <label className="block text-sm font-medium text-gray-100">Email Address</label>
              <input
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 px-3 py-2 w-full border rounded-md text-gray-900 bg-gray-100 focus:outline-none focus:ring-primary"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-100">Password</label>
              <input
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 px-3 py-2 w-full border rounded-md text-gray-900 bg-gray-100 focus:outline-none focus:ring-primary"
              />
            </div>
            <div className="flex items-center justify-between">
              <div></div>
              <div>
                <Link to="/forgot-password" className="text-sm text-primary hover:underline">
                  Forgot Password?
                </Link>
              </div>
            </div>
            <button
              type="submit"
              className="w-full py-2 bg-primary text-white rounded-md hover:bg-primary-dark transition"
            >
              Sign In
            </button>
          </form>
          {/* <div className="flex items-center my-4">
            <hr className="flex-1" />
            <span className="px-4 text-gray-300">or continue with</span>
            <hr className="flex-1" />
          </div>
          <div className="flex space-x-4">
            <button
              onClick={handleGoogleSignIn}
              className="flex items-center justify-center px-4 py-2 w-full border border-gray-300 rounded-md hover:bg-gray-50 hover:text-black transition-colors"
            >
              <img src={googleIcon} alt="Google" className="w-5 h-5 mr-2" />
              Google
            </button>
            <button
              onClick={handleAppleSignIn}
              className="flex items-center justify-center px-4 py-2 w-full border border-gray-300 rounded-md hover:bg-gray-50 hover:text-black transition-colors"
            >
              <img src={appleIcon} alt="Apple" className="w-5 h-5 mr-2" />
              Apple
            </button>
          </div> */}
          <p className="text-center text-sm text-gray-300">
            Don't have an account?{' '}
            <Link to="/signup" className="text-primary hover:underline">
              Sign Up
            </Link>
          </p>
        </div>
      </div>
      {/* Right side with benefits or welcome message */}
      <div className="hidden lg:flex w-1/2 bg-primary items-center justify-center p-12">
        <div>
          <h1 className="text-4xl font-bold text-white mb-6">Welcome Back!</h1>
          <p className="text-lg text-gray-100">
            Sign in to access your dashboard and manage your integrations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
