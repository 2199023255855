// src/pages/ConnectorOperations.js
import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  ArrowLeftIcon,
  MagnifyingGlassIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/solid';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015, atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { ConnectorsContext } from '../../contexts/ConnectorsContext';

// Helper function to generate Python request example
const generatePythonRequestExample = (operation, connectorId) => {
  const { id: operationId, keyword_mapping, parameters } = operation;

  const mappedParams = parameters.map((param) => {
    const operationParamName = keyword_mapping?.[param.default || param.name] || param.default || param.name;
    const exampleValue = param.type === 'string' ? '""' : param.type === 'integer' ? '0' : 'null';

    return `        "${operationParamName}": ${exampleValue},`;
  });

  return `import fiscus_sdk

response = client.execute(
    connector="${connectorId}",
    operation="${operationId}",
    params={
${mappedParams.join('\n')}
    }
)

print(response)
`;
};

const ConnectorOperations = () => {
	console.log("ConnectorOperations component rendered");
  const { connectorId } = useParams();
  const { connectors, operations, fetchConnectorOperations } = useContext(ConnectorsContext);

  const [selectedOperation, setSelectedOperation] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showOptional, setShowOptional] = useState(false); // Toggle for optional properties
  const [isDarkMode, setIsDarkMode] = useState(false);

  const connector = connectors?.find((conn) => conn.id === connectorId);

  // Check for cached operations; fetch if missing
  useEffect(() => {
	if (connector && !operations[connectorId]) {
	  console.log(`Fetching operations for connector: ${connectorId}`);
	  fetchConnectorOperations(connectorId);  // Check if this function is called
	}
  }, [connector, connectorId, operations, fetchConnectorOperations]);
  
  const connectorOperations = operations[connectorId] || [];
  console.log("Connector Operations after fetch:", connectorOperations);  // Log operations array
  
  useEffect(() => {
	if (connectorOperations.length > 0) {
	  console.log("Setting selected operation:", connectorOperations[0]);
	  setSelectedOperation(connectorOperations[0]);  // Log selected operation immediately
	}
  }, [connectorOperations]);
  
  useEffect(() => {
	console.log("Selected Operation:", selectedOperation);  // Check if selectedOperation is updated
	console.log("Selected Operation Parameters:", selectedOperation?.parameters);  // Log parameters
  }, [selectedOperation]);
  

  // Detect dark mode preference
  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => setIsDarkMode(e.matches);

    setIsDarkMode(darkModeMediaQuery.matches);
    darkModeMediaQuery.addEventListener('change', handleChange);

    return () => darkModeMediaQuery.removeEventListener('change', handleChange);
  }, []);

  if (!connector) {
    return (
      <div className="p-6">
        <p className="text-gray-700 dark:text-gray-300">Connector not found.</p>
        <Link to="/connectors" className="text-primary hover:underline flex items-center mt-4">
          <ArrowLeftIcon className="w-5 h-5 mr-1" />
          Back to Connectors
        </Link>
      </div>
    );
  }

  const filteredOperations = connectorOperations.filter((operation) =>
    operation.id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="flex flex-col lg:flex-row">
      {/* Sidebar */}
      <aside className="w-full lg:w-64 bg-gray-50 dark:bg-gray-800 p-6">
        <div className="mb-6">
          <Link to="/connectors" className="text-primary hover:underline flex items-center mb-4">
            <ArrowLeftIcon className="w-5 h-5 mr-1" />
            Back to Connectors
          </Link>
          <h3 className="text-2xl font-bold text-gray-800 dark:text-white mb-2">{connector.properName}</h3>
          <p className="text-sm text-gray-600 dark:text-gray-300">
            {connectorOperations.length} Operations
          </p>
        </div>
        <div className="relative mb-6">
          <input
            type="text"
            placeholder="Search operations..."
            className="w-full px-4 py-2 border rounded-md dark:bg-gray-700 dark:text-white"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-300 absolute top-2.5 right-3" />
        </div>
        <ul className="space-y-1">
          {filteredOperations.map((operation) => (
            <li key={operation.id}>
              <button
                onClick={() => setSelectedOperation(operation)}
                className={clsx(
                  'flex items-center w-full px-3 py-2 rounded-md text-left',
                  selectedOperation?.id === operation.id
                    ? 'bg-primary text-white'
                    : 'text-gray-800 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
                )}
              >
                {operation.id}
              </button>
            </li>
          ))}
        </ul>
      </aside>

      {/* Main Content */}
      <div className="flex-1 p-6 bg-white dark:bg-gray-900 min-h-screen">
        {filteredOperations.length === 0 ? (
          <p className="text-gray-700 dark:text-gray-300">No operations found.</p>
        ) : selectedOperation ? (
          <div className="mb-12">
            <div className="mb-4">
              <h2 className="text-3xl font-semibold text-gray-800 dark:text-white">
                {selectedOperation.id}
              </h2>
              <p className="text-lg text-gray-600 dark:text-gray-300">
                {connector.propertyDescriptions?.[selectedOperation.id] || ''}
              </p>
            </div>

            <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-md shadow-inner">
              <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4">Parameters</h3>
              <table className="w-full mb-6 table-auto">
                <thead>
                  <tr>
                    <th className="text-left text-gray-700 dark:text-gray-300 pb-2">Name</th>
                    <th className="text-left text-gray-700 dark:text-gray-300 pb-2">Type</th>
                    <th className="text-left text-gray-700 dark:text-gray-300 pb-2">Description</th>
                    <th className="text-left text-gray-700 dark:text-gray-300 pb-2">Required</th>
                  </tr>
                </thead>
                <tbody>
				{(selectedOperation.parameters && Array.isArray(selectedOperation.parameters) ? selectedOperation.parameters : [])
					.sort((a, b) => {
					const aIsRequired = selectedOperation.required_properties?.[a.default || a.name];
					const bIsRequired = selectedOperation.required_properties?.[b.default || b.name];
					if (aIsRequired && !bIsRequired) return -1;
					if (!aIsRequired && bIsRequired) return 1;
					return 0;
					})
					.map((param) => {
					const displayName = param.default || param.name;
					const isRequired = selectedOperation.required_properties?.[displayName];
					const description =
						selectedOperation.propertyDescriptions?.[displayName] ||
						selectedOperation.propertyDescriptions?.[param.name] ||
						'';

						// Define color classes for each type
						const typeColorClass = (() => {
							switch (param.type) {
							case 'string':
								return 'text-blue-500 dark:text-blue-300';
							case 'integer':
								return 'text-purple-500 dark:text-purple-300';
							case 'boolean':
								return 'text-green-500 dark:text-green-300';
							case 'object':
								return 'text-indigo-500 dark:text-indigo-300';
							case 'array':
								return 'text-yellow-500 dark:text-yellow-300';
							case 'number':
								return 'text-teal-500 dark:text-teal-300';
							case 'date':
								return 'text-red-500 dark:text-red-300';
							case 'any':
								return 'text-gray-500 dark:text-gray-400 italic';
							default:
								return 'text-gray-800 dark:text-gray-300'; // default color
							}
						})();

						return isRequired || showOptional ? (
							<tr key={param.name} className="border-t dark:border-gray-700">
							<td className="py-3 text-gray-800 dark:text-gray-300">{displayName}</td>
							<td className={`py-3 font-semibold ${typeColorClass}`}>{param.type}</td>
							<td className={`py-3 ${showOptional ? 'px-4' : ''} text-gray-600 dark:text-gray-400`}>
								{description}
							</td>
							<td className="py-3 text-gray-800 dark:text-gray-300 flex items-center">
								{isRequired ? (
								<>
									<CheckCircleIcon className="w-5 h-5 text-green-500 mr-1" />
									<span className="text-sm">Required</span>
								</>
								) : (
								<>
									<ExclamationCircleIcon className="w-5 h-5 text-yellow-500 mr-1" />
									<span className="text-sm">Optional</span>
								</>
								)}
							</td>
							</tr>
						) : null;
						})}
					</tbody>
              </table>
              {/* Toggle for optional properties */}
              <button
                onClick={() => setShowOptional((prev) => !prev)}
                className="flex items-center text-primary hover:underline mt-4"
              >
                <ChevronDownIcon
                  className={clsx('w-5 h-5 mr-1 transition-transform', showOptional ? 'rotate-180' : 'rotate-0')}
                />
                {showOptional ? 'Hide Optional Properties' : 'Show Optional Properties'}
              </button>

              {/* Request Example */}
              <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4 mt-8">
                Request Example
              </h3>
              <div className={clsx('rounded-md overflow-hidden mb-6', isDarkMode ? 'bg-gray-800' : 'bg-gray-100')}>
                <div className={clsx('px-4 py-2 font-mono text-sm', isDarkMode ? 'bg-gray-900 text-gray-300' : 'bg-gray-200 text-gray-800')}>
                  Python
                </div>
                <SyntaxHighlighter
                  language="python"
                  style={isDarkMode ? vs2015 : atomOneLight}
                  customStyle={{
                    margin: 0,
                    padding: '1rem',
                    background: isDarkMode ? '#2d2d2d' : '#f5f5f5',
                  }}
                >
                  {generatePythonRequestExample(selectedOperation, connectorId)}
                </SyntaxHighlighter>
              </div>

              {/* Response Examples */}
              <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4">Response Examples</h3>
              <Tab.Group>
                <Tab.List className="flex space-x-1 rounded-t-md bg-gray-200 dark:bg-gray-700 p-1">
                  {selectedOperation.responses.map((response) => (
                    <Tab
                      key={response.status}
                      className={({ selected }) =>
                        clsx(
                          'w-full py-2 text-sm leading-5 font-medium text-gray-700 dark:text-gray-300 rounded-md',
                          selected ? 'bg-white dark:bg-gray-800 shadow' : 'hover:bg-gray-100 dark:hover:bg-gray-600'
                        )
                      }
                    >
                      {response.status} {response.statusText}
                    </Tab>
                  ))}
                </Tab.List>
                <Tab.Panels className={clsx('rounded-b-md overflow-hidden', isDarkMode ? 'bg-gray-800' : 'bg-gray-100')}>
                  {selectedOperation.responses.map((response) => (
                    <Tab.Panel key={response.status}>
                      <div className={clsx('px-4 py-2 font-mono text-sm', isDarkMode ? 'bg-gray-900 text-gray-300' : 'bg-gray-200 text-gray-800')}>
                        JSON
                      </div>
                      <SyntaxHighlighter
                        language="json"
                        style={isDarkMode ? vs2015 : atomOneLight}
                        customStyle={{
                          margin: 0,
                          padding: '1rem',
                          background: isDarkMode ? '#2d2d2d' : '#f5f5f5',
                        }}
                      >
                        {response.body ? JSON.stringify(response.body, null, 2) : 'No Content'}
                      </SyntaxHighlighter>
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        ) : (
          <p className="text-gray-700 dark:text-gray-300">Select an operation to view details.</p>
        )}
      </div>
    </div>
  );
};

export default ConnectorOperations;
