// src/components/ApiKeyModal.js
import React, { useState } from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/solid';

const ApiKeyModal = ({ onClose, onGenerate }) => {
  const [keyName, setKeyName] = useState('');
  const [environment, setEnvironment] = useState('production');
  const [apiKey, setApiKey] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newKeyData = { keyName, environment };
    const result = await onGenerate(newKeyData);
    if (result && result.apiKey) {
      setApiKey(result.apiKey);
    } else {
      alert('Failed to generate API key.');
    }
  };

  const handleCopyKey = () => {
    navigator.clipboard.writeText(apiKey);
    alert('API Key copied to clipboard!');
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-lg w-full p-6">
        {apiKey ? (
          <>
            <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">API Key Generated</h2>
            <p className="mb-4 text-gray-700 dark:text-gray-300">
              Please copy your new API key now. You won't be able to see it again!
            </p>
            <div className="mb-4">
              <div className="flex items-center justify-between px-4 py-2 border rounded-md bg-gray-100 dark:bg-gray-700 dark:text-white font-mono text-sm break-all">
                {apiKey}
                <button
                  onClick={handleCopyKey}
                  className="text-gray-500 dark:text-gray-400 hover:text-primary"
                >
                  <DocumentDuplicateIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark"
              >
                Done
              </button>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">Generate New API Key</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 dark:text-gray-300 mb-2">Key Name</label>
                <input
                  type="text"
                  value={keyName}
                  onChange={(e) => setKeyName(e.target.value)}
                  required
                  className="w-full px-4 py-2 border rounded-md bg-gray-100 dark:bg-gray-700 dark:text-white focus:ring-2 focus:ring-primary"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 dark:text-gray-300 mb-2">Environment</label>
                <select
                  value={environment}
                  onChange={(e) => setEnvironment(e.target.value)}
                  className="w-full px-4 py-2 border rounded-md bg-gray-100 dark:bg-gray-700 dark:text-white focus:ring-2 focus:ring-primary"
                >
                  <option value="production">Production</option>
                  <option value="sandbox">Sandbox</option>
                </select>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={onClose}
                  className="mr-2 px-4 py-2 bg-gray-200 dark:bg-gray-700 rounded hover:bg-gray-300"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark"
                >
                  Generate Key
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ApiKeyModal;
