// src/pages/Return.js
import React, { useEffect, useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { BillingContext } from '../contexts/BillingContext';

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const { getSessionStatus } = useContext(BillingContext); // Use getSessionStatus instead of fetchSessionStatus

  useEffect(() => {
    const sessionId = new URLSearchParams(window.location.search).get('session_id');
    if (sessionId) {
      getSessionStatus(sessionId).then(({ status, customerEmail }) => {
        setStatus(status);
        setCustomerEmail(customerEmail);
      });
    }
  }, [getSessionStatus]);

  if (status === 'open') {
    return <Navigate to="/checkout" />;
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to {customerEmail}.
          If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
      </section>
    );
  }

  return null;
};

export default Return;
