import React, { useState, useContext } from 'react';
import { PowerIcon, EyeIcon, LinkIcon, Cog6ToothIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { ConnectorsContext } from '../../contexts/ConnectorsContext';

const ConnectorCard = ({ connector, onConnect, onDelete }) => {
  const { addConnectorToOrg } = useContext(ConnectorsContext);
  const { id, name, category, icon, status, configurations } = connector;
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const handleConnect = async () => {
    const hasConfigFields = configurations.some((config) => config.fields?.length > 0);

    if (!hasConfigFields) {
      setIsSaving(true);
      await onConnect(id);
      setIsSaving(false);
    } else {
      onConnect(connector);
    }
  };

  const handleDisconnect = () => {
    setConfirmType('disconnect');
    setShowConfirm(true);
  };

  const confirmDisconnect = async () => {
    setIsSaving(true);

    // Only send connector ID, name, and updated status for disconnection
    await addConnectorToOrg(id, { connectorName: connector.connectorName, status: 'Disconnected' });

    setShowConfirm(false);
    setConfirmType('');
    setIsSaving(false);
  };


  const cancelDisconnect = () => {
    setShowConfirm(false);
    setConfirmType('');
  };

  const confirmDelete = () => {
    onDelete(id);
    setShowConfirm(false);
    setConfirmType('');
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 flex flex-col relative">
      {status && (
        <button
          onClick={handleDisconnect}
          className="absolute top-4 right-4 text-gray-400 hover:text-red-500 dark:hover:text-red-400 transition"
          aria-label={`Disconnect ${name} Connector`}
        >
          <PowerIcon className="w-5 h-5" />
        </button>
      )}

      <div className="flex items-center mb-4">
        <img src={icon} alt={`${name} icon`} className="w-12 h-12 mr-4 object-contain" crossOrigin="anonymous" />
        <div>
          <h3 className="text-xl font-semibold text-gray-800 dark:text-white">{name}</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">{category}</p>
        </div>
      </div>

      <div className="mb-4">
        <span
          className={clsx(
            'inline-block px-3 py-1 text-xs font-semibold rounded-full',
            status ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
          )}
        >
          {status ? 'Connected' : 'Not Connected'}
        </span>
      </div>

      <div className="mt-auto flex flex-wrap justify-center gap-4">
        <button
          onClick={handleConnect}
          className={clsx(
            'flex items-center justify-center w-full sm:w-auto flex-grow px-4 py-2',
            !status ? 'bg-green-600 hover:bg-green-700' : 'bg-blue-500 hover:bg-blue-600',
            'text-white rounded-md transition focus:outline-none'
          )}
          title={!status ? 'Connect Connector' : 'Configure Connector'}
          disabled={isSaving}
        >
          {isSaving ? (
            <>
              <ArrowPathIcon className="w-5 h-5 mr-2 animate-spin" />
              Saving...
            </>
          ) : (
            <>
              {!status ? (
                <>
                  <LinkIcon className="w-5 h-5 mr-2" />
                  Connect
                </>
              ) : (
                <>
                  <Cog6ToothIcon className="w-5 h-5 mr-2" />
                  Configure
                </>
              )}
            </>
          )}
        </button>

        <Link
          to={`/connectors/${id}/operations`}
          className={clsx(
            'flex items-center justify-center w-full sm:w-auto flex-grow px-4 py-2',
            'bg-gray-500 hover:bg-gray-600',
            'text-white rounded-md transition'
          )}
          title="View Operations"
        >
          <EyeIcon className="w-5 h-5 mr-2" />
          Operations
        </Link>
      </div>

      {showConfirm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-80">
            <h4 className="text-lg font-semibold text-gray-800 dark:text-white mb-4">
              {confirmType === 'disconnect' ? 'Confirm Disconnect' : 'Confirm Delete'}
            </h4>
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              {confirmType === 'disconnect' ? (
                <>
                  Are you sure you want to disconnect <strong>{name}</strong>? This action may affect your integrations.
                </>
              ) : (
                <>
                  Are you sure you want to delete <strong>{name}</strong>? This action cannot be undone.
                </>
              )}
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={cancelDisconnect}
                className="px-4 py-2 bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded hover:bg-gray-400 dark:hover:bg-gray-600 transition"
              >
                Cancel
              </button>
              <button
                onClick={confirmType === 'disconnect' ? confirmDisconnect : confirmDelete}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
              >
                {confirmType === 'disconnect' ? 'Disconnect' : 'Delete'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectorCard;
