// src/App.js
import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
import { LoadingContext } from './contexts/LoadingContext';
import { setLoadingFunction } from './services/axiosConfig'; // Updated import
import Sidebar from './components/Utilities/Sidebar';
import Header from './components/Utilities/Header';
import DashboardHome from './pages/DashboardHome';
import ApiKeysPage from './pages/ApiKeysPage';
import ConnectorsPage from './pages/ConnectorsPage';
import ConnectorOperations from './components/Connector/ConnectorOperations';
import UsageMetricsPage from './pages/UsageMetricsPage';
import ApiCallLogsPage from './pages/ApiCallLogsPage';
import DocsPage from './pages/DocsPage';
import SettingsPage from './pages/SettingsPage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ConfirmationPage from './pages/ConfirmationPage';
import LoadingModal from './components/Utilities/LoadingModal';
import CheckoutForm from './pages/CheckoutForm';
import Return from './pages/Return';

// Importing the new icons
import {
  ArrowLeftEndOnRectangleIcon,
  ArrowRightStartOnRectangleIcon,
} from '@heroicons/react/24/solid'; // Ensure this path matches your Heroicons version

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [connectors, setConnectors] = useState([]);

  // Access loading functions from context
  const { showLoading, hideLoading } = useContext(LoadingContext);

  useEffect(() => {
    // Set the loading functions for Axios
    setLoadingFunction({ showLoading, hideLoading });
  }, [showLoading, hideLoading]);

  return (
    <Router>
      <AuthContext.Consumer>
        {({ isAuthenticated }) =>
          isAuthenticated ? (
            <div className="flex h-screen overflow-hidden">
              {/* Sidebar */}
              <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

              {/* Toggle Button */}
              <button
                onClick={() => setSidebarOpen(!sidebarOpen)}
                className="fixed top-4 left-4 text-gray-700 dark:text-gray-300 focus:outline-none z-50"
                aria-label={sidebarOpen ? 'Close sidebar' : 'Open sidebar'}
              >
                {sidebarOpen ? (
                  <ArrowLeftEndOnRectangleIcon className="w-8 h-8" />
                ) : (
                  <ArrowRightStartOnRectangleIcon className="w-8 h-8" />
                )}
              </button>

              {/* Main Content */}
              <div
                className={`flex flex-col flex-1 transition-all duration-200 ${
                  sidebarOpen ? 'ml-64' : 'ml-0'
                }`}
              >
                <Header />

                {/* Main Content */}
                <main className="flex-1 overflow-y-auto p-6 bg-gray-100 dark:bg-gray-900">
                  <Routes>
                    <Route path="/" element={<DashboardHome />} />
                    <Route path="/api-keys" element={<ApiKeysPage />} />
                    <Route
                      path="/connectors/:connectorId/operations"
                      element={<ConnectorOperations connectors={connectors} />}
                    />
                    <Route
                      path="/connectors"
                      element={<ConnectorsPage connectors={connectors} setConnectors={setConnectors} />}
                    />
                    {/* <Route path="/usage-metrics" element={<UsageMetricsPage />} /> */}
                    <Route path="/api-call-logs" element={<ApiCallLogsPage />} />
                    <Route path="/docs" element={<DocsPage />} />
                    <Route path="/settings" element={<SettingsPage />} />
					<Route path="/checkout" element={<CheckoutForm />} />
        			<Route path="/return" element={<Return />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </main>
              </div>
            </div>
          ) : (
            <Routes>
              <Route path="/signin" element={<SignInPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/confirm" element={<ConfirmationPage />} />
              <Route path="*" element={<Navigate to="/signin" replace />} />
            </Routes>
          )
        }
      </AuthContext.Consumer>
      <LoadingModal /> {/* Loading Modal */}
    </Router>
  );
}

export default App;
