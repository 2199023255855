// src/components/ApiCall/LogsOverview.js
import React, { useMemo } from 'react';
import OverviewCard from '../Utilities/OverviewCard'; // Import OverviewCard
import { ChartPieIcon, ExclamationTriangleIcon, ClockIcon } from '@heroicons/react/24/solid'; // Import relevant icons

const LogsOverview = ({ logs, isLoading }) => {
	const overviewData = useMemo(() => {
		if (!logs || logs.length === 0) {
		  return {
			totalCalls: 0,
			errorRate: 0,
			avgResponseTime: 0,
		  };
		}
	  
		const totalCalls = logs.length;
		const errorCalls = logs.filter((log) => Number(log.status) >= 400).length;
		const errorRate = totalCalls ? ((errorCalls / totalCalls) * 100).toFixed(2) : 0;
		const avgResponseTime = totalCalls
		  ? (logs.reduce((sum, log) => sum + Number(log.responseTime), 0) / totalCalls).toFixed(2)
		  : 0;
	  
		return {
		  totalCalls,
		  errorRate,
		  avgResponseTime,
		};
	  }, [logs]);
	  

  if (isLoading && logs.length === 0) {
    return <p>Loading overview data...</p>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
      {/* Total API Calls */}
      <OverviewCard
        title="Total API Calls"
        value={overviewData.totalCalls}
        icon={ChartPieIcon}
        color="blue"
      />

      {/* Error Rate */}
      <OverviewCard
        title="Error Rate"
        value={`${overviewData.errorRate}%`}
        icon={ExclamationTriangleIcon}
        color="red"
      />

      {/* Avg Response Time */}
      <OverviewCard
        title="Avg Response Time"
        value={`${overviewData.avgResponseTime} ms`}
        icon={ClockIcon}
        color="green"
      />
    </div>
  );
};

export default LogsOverview;
