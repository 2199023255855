// src/components/Utilities/Header.js
import React from 'react';
import { useTheme } from './ThemeContext';
import { SunIcon, MoonIcon } from '@heroicons/react/24/solid';

const Header = () => {
  const { darkMode, toggleDarkMode } = useTheme();

  return (
    <header className="flex items-center justify-center px-6 py-4 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
      <h2 className="text-xl font-semibold text-gray-800 dark:text-white">Dashboard</h2>
      <div className="absolute right-6">
        <button
          onClick={toggleDarkMode}
          className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600"
        >
          {darkMode ? (
            <SunIcon className="w-6 h-6 text-yellow-400" />
          ) : (
            <MoonIcon className="w-6 h-6 text-gray-800" />
          )}
        </button>
      </div>
    </header>
  );
};

export default Header;
