// src/components/Utilities/LoadingModal.js
import React, { useContext } from 'react';
import { LoadingContext } from '../../contexts/LoadingContext';

const LoadingModal = () => {
  const { loading } = useContext(LoadingContext);

  if (!loading) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
      <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="flex items-center space-x-2">
          <div className="w-6 h-6 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
          <span className="text-gray-800 dark:text-gray-200">Processing your request...</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingModal;
