// src/components/Settings/BillingSettings.js
import React, { useState, useEffect, useContext } from 'react';
import { CreditCardIcon, ArrowUpOnSquareIcon, ArrowDownOnSquareIcon } from '@heroicons/react/24/solid';
import UsageMeter from './UsageMeter';
import { BillingContext } from '../../contexts/BillingContext';

const BillingSettings = () => {
  const [billingInfo, setBillingInfo] = useState({
    hasPaymentMethod: false,
    plan: 'Free', // Initial plan
  });

  const [usageData, setUsageData] = useState({
    apiCalls: 0, // Initial API calls
  });

  const { createCheckoutSession } = useContext(BillingContext); // Access createCheckoutSession from context

  // Function to fetch usage data
  const fetchUsageData = async () => {
    try {
      // Replace with your actual API call
      const response = await fetch('/api/usage'); // Example endpoint
      if (!response.ok) {
        throw new Error('Failed to fetch usage data');
      }
      const data = await response.json();
      return data; // Expected to return { apiCalls: number }
    } catch (error) {
      console.log(error);
      return { apiCalls: 0 }; // Fallback data
    }
  };

  // Function to fetch billing information
  const fetchBillingInformation = async () => {
    try {
      // Replace with your actual API call
      const response = await fetch('/api/billing-info'); // Example endpoint
      if (!response.ok) {
        throw new Error('Failed to fetch billing information');
      }
      const data = await response.json();
      return data; // Expected to return { hasPaymentMethod: boolean, plan: string }
    } catch (error) {
      console.log(error);
      return { hasPaymentMethod: false, plan: 'Free' }; // Fallback data
    }
  };

  // Function to remove payment method
  const removePaymentMethod = async () => {
    try {
      // Replace with your actual API call to remove payment method
      const response = await fetch('/api/remove-payment-method', {
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error('Failed to remove payment method');
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // Handle adding payment method (trigger Stripe)
  const handleAddPaymentMethod = async () => {
    try {
      const sessionId = await createCheckoutSession();
      if (sessionId) {
        // Redirect to Stripe checkout using the session ID
        window.location.href = `/checkout?session_id=${sessionId}`;
      } else {
        throw new Error('Session creation failed.');
      }
    } catch (error) {
      console.log(error);
      alert('Failed to initiate payment method addition.');
    }
  };

  // Handle removing payment method
  const handleRemovePaymentMethod = async () => {
    try {
      const success = await removePaymentMethod();
      if (success) {
        setBillingInfo((prev) => ({ ...prev, hasPaymentMethod: false }));
        alert('Payment method removed successfully!');
      } else {
        throw new Error('Removal failed.');
      }
    } catch (error) {
      console.log(error);
      alert('Failed to remove payment method.');
    }
  };

  // Determine plan based on API calls
  const determinePlan = (apiCalls) => {
    if (apiCalls <= 50000) return 'Free';
    if (apiCalls <= 2500000) return 'Usage';
    return 'Enterprise';
  };

  // Calculate usage cost based on API calls
  const calculateUsageCost = (apiCalls) => {
    if (apiCalls <= 50000) return 0; // Free plan
    if (apiCalls <= 2500000)
      return (apiCalls - 50000) * 0.0002; // First paid tier
    if (apiCalls <= 10000000)
      return (2500000 - 50000) * 0.0002 + (apiCalls - 2500000) * 0.00019; // Second paid tier
    return (
      (2500000 - 50000) * 0.0002 +
      (10000000 - 2500000) * 0.00019 +
      (apiCalls - 10000000) * 0.00018
    ); // Third paid tier
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usage = await fetchUsageData(); // Fetch real usage data
        setUsageData(usage);
        const plan = determinePlan(usage.apiCalls);
        const billing = await fetchBillingInformation(); // Fetch real billing info
        setBillingInfo({
          hasPaymentMethod: billing.hasPaymentMethod,
          plan,
        });
      } catch (error) {
        console.log('Error fetching billing data:', error);
        alert('Failed to load billing data. Please try again later.');
      }
    };

    fetchData();
  }, []);

  const currentPlan = billingInfo.plan;
  const usageCost = calculateUsageCost(usageData.apiCalls);

  // Handle Upgrade based on current plan
  const handleUpgrade = () => {
    if (currentPlan === 'Free') {
      // Trigger Stripe checkout
      handleAddPaymentMethod();
    } else if (currentPlan === 'Usage') {
      // Log the upgrade attempt for now
      console.log('Usage plan user attempted to upgrade.');
      alert('Upgrade functionality is not implemented yet.');
      // Future implementation: Send an email or notify the admin
    } else if (currentPlan === 'Enterprise') {
      // Optionally handle Enterprise plan upgrades if applicable
      console.log('Enterprise plan user attempted to upgrade.');
      alert('You are already on the Enterprise plan.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-50 dark:bg-gray-900 rounded-lg shadow">
      <h2 className="text-3xl font-bold text-gray-800 dark:text-white mb-6">Billing Settings</h2>

      {/* Usage Meter */}
      <UsageMeter usageData={usageData} />

      {/* Your Plan Section */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 mb-6">
        <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4">Your Plan</h3>

        <div className="flex flex-col md:flex-row md:space-x-6">
          {/* Plan Details */}
          <div className="flex-1">
            {/* Free Plan */}
            <div className="border border-gray-200 dark:border-gray-700 rounded-lg p-4 mb-4">
              <h4 className="text-xl font-semibold text-gray-700 dark:text-gray-300">Free</h4>
              <p className="mt-2 text-gray-600 dark:text-gray-400">Up to 50,000 API calls per month.</p>
            </div>
            {/* Usage Plan */}
            <div className="border border-gray-200 dark:border-gray-700 rounded-lg p-4 mb-4">
              <h4 className="text-xl font-semibold text-gray-700 dark:text-gray-300">Usage</h4>
              <p className="mt-2 text-gray-600 dark:text-gray-400">Pay as you go beyond 50,000 API calls.</p>
            </div>
            {/* Enterprise Plan */}
            <div className="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
              <h4 className="text-xl font-semibold text-gray-700 dark:text-gray-300">Enterprise</h4>
              <p className="mt-2 text-gray-600 dark:text-gray-400">Custom solutions for large-scale needs.</p>
            </div>
          </div>

          {/* Current Plan and Upgrade Button */}
          <div className="flex-1 mt-6 md:mt-0">
            <div className="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
              <h4 className="text-xl font-semibold text-gray-700 dark:text-gray-300">
                Current Plan: {currentPlan}
              </h4>
              <button
                onClick={handleUpgrade}
                className="mt-4 w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center"
              >
                <ArrowUpOnSquareIcon className="w-5 h-5 mr-2" />
                Upgrade Plan
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Plan Comparison Section */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4">Plan Comparison</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead>
                <tr className="bg-gray-200 dark:bg-gray-700">
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300">Feature</th>
                  <th className="px-4 py-2 text-center text-sm font-medium text-gray-700 dark:text-gray-300">Free</th>
                  <th className="px-4 py-2 text-center text-sm font-medium text-gray-700 dark:text-gray-300">Usage</th>
                  <th className="px-4 py-2 text-center text-sm font-medium text-gray-700 dark:text-gray-300">Enterprise</th>
                </tr>
              </thead>
              <tbody>
                {/* API Calls */}
                <tr className="border-t border-gray-200 dark:border-gray-700">
                  <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">API Calls</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">50,000</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Unlimited</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Unlimited</td>
                </tr>
                
                {/* Cost per API Call */}
                <tr className="border-t border-gray-200 dark:border-gray-700">
                  <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">Monthly Cost per API Call</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">—</td>
                  <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                    <div className="text-center space-y-1">
                      <span className="block">
                        <span className="text-gray-700 dark:text-gray-300">$0.0002 </span>
                        <span className="text-gray-600 dark:text-gray-400">(up to 2.5M)</span>
                      </span>
                      <span className="block">
                        <span className="text-gray-700 dark:text-gray-300">$0.00019 </span>
                        <span className="text-gray-600 dark:text-gray-400">(2.5M - 10M)</span>
                      </span>
                      <span className="block">
                        <span className="text-gray-700 dark:text-gray-300">$0.00018 </span>
                        <span className="text-gray-600 dark:text-gray-400">(above 10M)</span>
                      </span>
                    </div>
                  </td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Custom Pricing</td>
                </tr>
                
                {/* Support */}
                <tr className="border-t border-gray-200 dark:border-gray-700">
                  <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">Support</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Email Support</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Priority Email Support</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Dedicated Account Manager</td>
                </tr>
                
                {/* Users & Seats */}
                <tr className="border-t border-gray-200 dark:border-gray-700">
                  <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">Users & Seats</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Unlimited</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Unlimited</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Unlimited</td>
                </tr>
                
                {/* Integrations & Events */}
                <tr className="border-t border-gray-200 dark:border-gray-700">
                  <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">Integrations & Events</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Unlimited</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Unlimited</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Unlimited</td>
                </tr>
                
                {/* Environments */}
                <tr className="border-t border-gray-200 dark:border-gray-700">
                  <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">Environments</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Staging & Production</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Staging & Production</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Custom Environments</td>
                </tr>
                
                {/* Log Retention */}
                <tr className="border-t border-gray-200 dark:border-gray-700">
                  <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">Log Retention</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">3 Months</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">1 Year</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">Up to 5 Years</td>
                </tr>
                
                {/* SLA Response Time */}
                <tr className="border-t border-gray-200 dark:border-gray-700">
                  <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">SLA Response Time</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">24 hours</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">12 hours</td>
                  <td className="px-4 py-2 text-center text-sm text-gray-700 dark:text-gray-300">24/7</td>
                </tr>
                
                {/* Add more rows as needed */}
              </tbody>
            </table>
          </div>
        </div>
    </div>
  );
};

export default BillingSettings;
