// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import AuthService from '../services/AuthService';
import { DashboardContext } from './DashboardContext';
import { ApiKeyContext } from './ApiKeyContext';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Initialize authentication state based on AuthService status
  const [isAuthenticated, setIsAuthenticated] = useState(AuthService.isAuthenticated());
  const [loginInProgress, setLoginInProgress] = useState(false); // Prevent duplicate logins
  
  // Conditionally access DashboardContext and ApiKeyContext only if they are available
  const { fetchDashboardData } = useContext(DashboardContext) || {};
  const { fetchApiKeys } = useContext(ApiKeyContext) || {};

  // Effect to sync authentication state when the component mounts
  useEffect(() => {
    setIsAuthenticated(AuthService.isAuthenticated());
  }, []);

  // Function to handle login and trigger additional data fetches
  const login = async (email, password) => {
    if (loginInProgress) return; // Prevent multiple login attempts

    setLoginInProgress(true);
    const result = await AuthService.login(email, password);
    
    if (result.success) {
      setIsAuthenticated(true);
      setLoginInProgress(false);
      onLogin(); // Trigger data fetches after successful login
    } else {
      setLoginInProgress(false);
    }
    return result;
  };

  // Additional data fetches after login
  const onLogin = useCallback(() => {
    // Only fetch data once after login
    if (fetchDashboardData) fetchDashboardData();
    if (fetchApiKeys) fetchApiKeys();
  }, [fetchDashboardData, fetchApiKeys]);

  // Logout function to clear authentication state and tokens
  const logout = () => {
    AuthService.logout();
    setIsAuthenticated(false);
  };

  // Registration function
  const register = async (email, password, name, organizationName) => {
    return await AuthService.register(email, password, name, organizationName);
  };

  // Confirm registration function
  const confirm = async (email, confirmationCode) => {
    return await AuthService.confirm(email, confirmationCode);
  };

  // Resend confirmation code function
  const resend = async (email) => {
    return await AuthService.resend(email);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, register, confirm, resend }}
    >
      {children}
    </AuthContext.Provider>
  );
};
