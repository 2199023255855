// src/pages/ApiKeysPage.js
import React from 'react';
import ApiKeyManagement from '../components/ApiKey/ApiKeyManagement';

const ApiKeysPage = () => (
  <div>
    <h2 className="text-2xl font-semibold text-gray-800 dark:text-white mb-6">API Keys</h2>
    <div className="grid grid-cols-1 gap-6">
      <ApiKeyManagement />
    </div>
  </div>
);

export default ApiKeysPage;
