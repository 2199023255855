// src/pages/ConfirmationPage.js
import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { LoadingContext } from '../contexts/LoadingContext';

const ConfirmationPage = () => {
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useContext(LoadingContext);

  const [searchParams] = useSearchParams();
  const email = location.state?.email || searchParams.get('email') || '';

  // Automatically fill confirmation code if present in the URL
  useEffect(() => {
    const codeFromUrl = searchParams.get('code');
    if (codeFromUrl) {
      setConfirmationCode(codeFromUrl);
    }
  }, [searchParams]);

  const handleConfirm = async (e) => {
    e.preventDefault();
    setError('');
    try {
      showLoading(); // Show spinner
      const result = await AuthService.confirm(email, confirmationCode);
      hideLoading(); // Hide spinner

      if (result.success) {
        alert('Account confirmed! You can now sign in.');
        navigate('/signin');
      } else {
        setError(result.message || 'Confirmation failed.');
      }
    } catch (err) {
      hideLoading(); // Hide spinner
      setError('An error occurred during confirmation.');
    }
  };

  const handleResendCode = async () => {
    setError('');
    try {
      showLoading(); // Show spinner
      const result = await AuthService.resendConfirmationCode(email);
      hideLoading(); // Hide spinner

      if (result.success) {
        alert('A new confirmation code has been sent to your email.');
      } else {
        setError(result.message || 'Failed to resend confirmation code.');
      }
    } catch (err) {
      hideLoading(); // Hide spinner
      setError('An error occurred while resending the confirmation code.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-8">
      <div className="max-w-md w-full space-y-4">
        <h2 className="text-3xl font-bold text-gray-100 mb-6">Confirm Your Account</h2>
        <p className="text-sm text-gray-300 mb-6">
          Enter the confirmation code sent to <strong>{email}</strong>.
        </p>
        <form onSubmit={handleConfirm} className="space-y-4">
          {error && <p className="text-red-500">{error}</p>}
          <div>
            <label className="block text-sm font-medium text-gray-100">Confirmation Code</label>
            <input
              type="text"
              required
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
              className="mt-1 px-3 py-2 w-full border rounded-md text-gray-900 bg-gray-100 focus:outline-none focus:ring-primary"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 bg-primary text-white rounded-md hover:bg-primary-dark transition"
          >
            Confirm Account
          </button>
        </form>
        
        <p className="text-center text-sm text-gray-300">
          Didn't receive a code?
        </p>
        <button
          onClick={handleResendCode}
          className="w-full py-2 bg-secondary text-white rounded-md hover:bg-secondary-dark transition mt-4"
        >
          Resend Confirmation Code
        </button>
      </div>
    </div>
  );
};

export default ConfirmationPage;
