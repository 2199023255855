// src/pages/DocsPage.js
import React from 'react';

const DocsPage = () => (
  <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
    <h2 className="text-2xl font-semibold text-gray-800 dark:text-white mb-6">Documentation</h2>
    <iframe
      src="https://docs.fiscusflows.com"
      title="Documentation"
      className="w-full h-[80vh] border rounded-md"
    ></iframe>
  </div>
);

export default DocsPage;
