// src/components/ConnectorList.js
import React from 'react';
import ConnectorCard from './ConnectorCard';

const ConnectorList = ({ connectors, category, searchQuery, onConnect, onDisconnect }) => {
  // Filter connectors based on category and search query, and ensure no null entries
  const filteredConnectors = connectors
    .filter(Boolean) // Removes any `null` or `undefined` items
    .filter((connector) => {
      const matchesCategory = category === 'All' || connector.category === category;
      const matchesSearch =
        searchQuery === '' ||
        connector.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        connector.category.toLowerCase().includes(searchQuery.toLowerCase());
      return matchesCategory && matchesSearch;
    });

  return (
    <div>
      {filteredConnectors.length === 0 ? (
        <p className="text-center text-gray-500 dark:text-gray-400">No connectors found.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredConnectors.map((connector) => (
            <ConnectorCard
              key={`${connector.id}-${connector.status}`} // Use both ID and status to force re-render
              connector={connector}
              onConnect={onConnect}
              onDisconnect={onDisconnect}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ConnectorList;
