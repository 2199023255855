// src/services/axiosConfig.js
import axios from 'axios';
import AuthService from './AuthService';

let showLoading = null;
let hideLoading = null;

export const setLoadingFunction = (functions) => {
  showLoading = functions.showLoading;
  hideLoading = functions.hideLoading;
};

// Configure Axios instance with base URL
const instance = axios.create({
//   baseURL: 'https://86ktfzke6h.execute-api.us-east-1.amazonaws.com/dev', // dev
  baseURL: 'https://5qva06rx59.execute-api.us-east-1.amazonaws.com/prod', // prod
  timeout: 10000, // Set a request timeout to prevent hanging
});

// Request interceptor to add authorization headers and show loading indicator
instance.interceptors.request.use(
  (config) => {
    const idToken = localStorage.getItem('idToken');
    const accessToken = localStorage.getItem('accessToken');

    // Set Authorization headers if tokens are available
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    if (idToken) {
      config.headers['x-fiscus-id-token'] = idToken;
    }

    // Show loading modal if not already showing
    if (showLoading) {
      showLoading();
    }

    return config;
  },
  (error) => {
    if (hideLoading) {
      hideLoading();
    }
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors without any retry logic
instance.interceptors.response.use(
  (response) => {
    // Hide loading indicator on successful response
    if (hideLoading) {
      hideLoading();
    }
    return response;
  },
  async (error) => {
    if (hideLoading) {
      hideLoading();
    }

    const originalRequest = error.config;

    // Handle 401 Unauthorized by attempting a single token refresh without retrying the request
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshSuccess = await AuthService.refreshTokens();
        if (refreshSuccess) {
          // Retrieve new tokens and set headers for the original request
          const idToken = localStorage.getItem('idToken');
          const accessToken = localStorage.getItem('accessToken');

          if (accessToken) {
            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
          }
          if (idToken) {
            originalRequest.headers['x-fiscus-id-token'] = idToken;
          }

          // Retry the original request one last time with updated tokens
          return instance(originalRequest);
        } else {
          // Log out if token refresh fails
          AuthService.logout();
          return Promise.reject(error);
        }
      } catch (refreshError) {
        // Reject with refresh error if token refresh itself fails
        return Promise.reject(refreshError);
      }
    }

    // Reject all other errors immediately without retrying
    return Promise.reject(error);
  }
);

export default instance;
