// src/pages/ConnectorsPage.js
import React, { useState, useContext, useEffect } from 'react';
import ConnectorList from '../components/Connector/ConnectorList';
import ConnectorCategories from '../components/Connector/ConnectorCategories';
import ConnectorSearch from '../components/Connector/ConnectorSearch';
import ConnectorModal from '../components/Connector/ConnectorModal';
import { ConnectorsContext } from '../contexts/ConnectorsContext';

const ConnectorsPage = () => {
  const { connectors, setConnectors, addConnectorToOrg } = useContext(ConnectorsContext); // Use addConnectorToOrg from context
  const [category, setCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedConnector, setSelectedConnector] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const handleConnect = async (connectorOrId, updatedData = null) => {
    const connectorId = typeof connectorOrId === 'string' ? connectorOrId : connectorOrId.id;
    const connectorIndex = connectors.findIndex((c) => c.id === connectorId);

    if (connectorIndex !== -1) {
      const connector = connectors[connectorIndex];
      const hasConfigFields = connector.configurations.some((config) => config.fields?.length > 0);

      if (updatedData) {
        // If save operation from modal, update status and configurations in state
        const updatedConnectors = [...connectors];
        updatedConnectors[connectorIndex] = {
          ...updatedConnectors[connectorIndex],
          ...updatedData,
        };
        setConnectors(updatedConnectors);
        setSelectedConnector(null);
      } else if (hasConfigFields) {
        // Open modal if configurations need input
        setSelectedConnector(connector);
      } else {
        // Direct connect without configuration modal
        setIsSaving(true);

        // Use addConnectorToOrg directly for backend
        await addConnectorToOrg(connector.id, connector.category);

        const updatedConnectors = [...connectors];
        updatedConnectors[connectorIndex] = { ...updatedConnectors[connectorIndex], status: true };
        setConnectors(updatedConnectors);
        setIsSaving(false);
      }
    }
  };

  const handleDisconnect = (connectorId) => {
    setConnectors((prevConnectors) =>
      prevConnectors.map((connector) =>
        connector.id === connectorId ? { ...connector, status: false } : connector
      )
    );
  };

  const handleDelete = (connectorId) => {
    setConnectors((prevConnectors) => prevConnectors.filter((connector) => connector.id !== connectorId));
  };

  const closeModal = () => {
    setSelectedConnector(null);
  };

  const categories = ['Email', 'Finance', 'Social', 'Messaging', 'Storage'];

  return (
    <div className="p-6 bg-gray-50 dark:bg-gray-900">
      <h2 className="text-3xl font-bold text-gray-800 dark:text-white mb-6">Connectors</h2>

      <div className="flex flex-col lg:flex-row lg:items-center lg:space-x-4 mb-6 space-y-4 lg:space-y-0">
        <ConnectorCategories categories={categories} onSelectCategory={setCategory} />
        <ConnectorSearch onSearch={setSearchQuery} />
      </div>

      {connectors ? (
        <ConnectorList
          connectors={connectors}
          category={category}
          searchQuery={searchQuery}
          onConnect={handleConnect}
          onDisconnect={handleDisconnect}
          onDelete={handleDelete}
          isSaving={isSaving}
        />
      ) : (
        <p className="text-center text-gray-500 dark:text-gray-400">Loading connectors...</p>
      )}

      {selectedConnector && (
        <ConnectorModal
          connector={selectedConnector}
          onClose={closeModal}
          onSave={handleConnect} // Pass handleConnect to save updates
        />
      )}
    </div>
  );
};

export default ConnectorsPage;
